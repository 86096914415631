import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import MainHeader from "../Pages/Header/MainHeader";
import FooterStyle from "../Pages/Footer/Footer";

const TermPolicy = () => {
    return (
        <div>
            {/* Header */}
            <MainHeader />

            {/* Terms of Use Section */}
            <section className="pt-[120px] pb-[50px] bg-gray-100">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <h1 className="text-[28px] font-bold text-black mb-4 black-class">Terms of Use</h1>
                            <p className="black-class">Please read this document carefully</p>

                            <p className="black-class">
                                JobCritics.com (the “Site”) is a Platform owned and operated by JobCritics Private Limited (“Company”). The Site is an intermediary as defined in Section 2 (w) of the Information Technology Act, 2000 of India. The Company makes available the Services to you on the condition that you abide by these Terms of Use. The Services (as defined below) give You (as defined below) the ability to access and share profiles and other content on the Site. The Company may, in its sole discretion, change any aspect of the Services or discontinue any Service without notice.
                            </p>
                            <p className="black-class">
                                THESE TERMS OF USE (“EULA” OR “TERMS OF USE”) CONSTITUTE A BINDING LEGAL AGREEMENT BETWEEN YOU (EITHER AS AN INDIVIDUAL OR ENTITY AS APPLICABLE) (“YOU” OR “YOUR”) AND THE COMPANY FOR THE SITE AND SOFTWARE PRODUCT(S) MADE AVAILABLE THROUGH IT, AS ALSO LICENSED MOBILE APPLICATION FOR ACCESSING THE SITE AND MAY INCLUDE ASSOCIATED MEDIA, AND ONLINE OR ELECTRONIC DOCUMENTATION (the “APPLICATION”). THE SITE AND APPLICATION ARE HEREINAFTER COLLECTIVELY REFERRED TO AS “PLATFORM”.
                            </p>
                            <p className="black-class">
                                BY INSTALLING, COPYING, OR OTHERWISE USING THE APPLICATION, YOU AGREE TO BE BOUND BY THE TERMS OF THIS EULA/TERMS OF USE. IF YOU DO NOT AGREE TO THIS EULA/TERMS OF USE, DO NOT INSTALL OR USE THE APPLICATION. THE APPLICATION IS INTENDED TO ENABLE ACCESS TO THE SITE THROUGH THE DEVICE SOLELY IN ACCORDANCE WITH THE TERMS OF THIS EULA/TERMS OF USE.
                            </p>
                            <p className="black-class">
                                ACCESS TO ANY PART OF THE PLATFORM OR ANY CONTENT DOWNLOADED FROM IT IS CONTINGENT ON YOUR ACCEPTANCE OF AND COMPLIANCE WITH THIS EULA/TERMS OF USE.
                            </p>
                            <p className="black-class">
                                ADDITIONALLY, BASED ON THE SERVICES CHOSEN, CERTAIN PRODUCT-SPECIFIC CONDITIONS MAY APPLY.
                            </p>

                            <h2 className="text-[22px] font-bold mt-4 black-class">Eligibility</h2>
                            <p className="black-class">
                                YOU REPRESENT AND WARRANT THAT:
                            </p>
                            <ul className="list-disc pl-5 black-class">
                                <li>YOU ARE COMPETENT TO ENTER INTO A VALID BINDING CONTRACT AS PER INDIAN LAW.</li>
                                <li>YOU ARE ELIGIBLE TO CONDUCT THE PURPOSE (AS DEFINED BELOW) LAWFULLY.</li>
                            </ul>
                            <p className="black-class">
                                <b>PURPOSE:</b> JobCritics is only meant for users with a bonafide intent to make informed career decisions using the information provided on the platform.
                            </p>

                            <h2 className="text-[22px] font-bold mt-4 black-class">Using JobCritics</h2>
                            <p className="black-class">
                                Content on JobCritics - Content from users, employers, advertisers, and other third parties is made available to you on JobCritics. Content means any work of authorship or information, which includes company reviews, company ratings, interview questions and answers, interview advice, interview tips, interview comments, company review comments, salary-related information, company information, employer responses, comments, opinions, postings, resume information, messages, text, files, images, photos, e-mail, data, or other materials present on JobCritics submitted by employers, individual users, and the content team of JobCritics. We do not control such Content, so you understand and agree that:
                            </p>
                            <ul className="list-disc pl-5 black-class">
                                <li>We are not responsible for, and do not endorse, any such Content, including advertising and information about third-party products and services, job ads, or the employer, interview, and salary-related information provided by other users.</li>
                                <li>We make no guarantees about the accuracy, currency, suitability, reliability, or quality of the information in such Content.</li>
                                <li>We assume no responsibility for unintended, objectionable, inaccurate, misleading, or unlawful Content made available by users, advertisers, and third parties.</li>
                            </ul>
                            <p className="black-class">
                                JobCritics cannot be held liable for claims arising from the Content provided by third parties. For more information, please contact us at <a href="mailto:support@jobcritics.com" className="text-blue-500 font-medium"> support@jobcritics.com</a>
                            </p>
                            <p className="black-class">
                                We allow users to post content about interviews, companies, and employers when they have been employed by the employer as a full-time, part-time, freelancer, retainer, or independent employee, or any individual who was hired by the employer to do a specific job, or any individual who has attended an interview at any company. We consider all users as ‘contributors’ with regard to Content left on JobCritics. While we provide the option for JobCritics reviewers to specify their job role or category of employment when they leave a review or interview experience, we do not consider this a requirement. It does not violate our Community Guidelines or these Terms of Use for a user to leave a review as an ‘interviewee’ or an ‘employee.’
                            </p>

                            <h2 className="text-[22px] font-bold mt-4 black-class">JobCritics Account</h2>
                            <p className="black-class">
                                <b>For Users</b><br />
                                For individual user accounts, you can log in to your JobCritics account using “Social Media Sites,” including but not limited to Gmail and LinkedIn. If you do not already have a JobCritics account but log in using a Social Media Site, this creates a JobCritics account using the same email address used in your Social Media Site account. If we cannot correspond with you via this email address, your submitted content on our portal may be rejected, and your account may be disabled. However, the addition, editing, removal, change, or modification of all or any portion of content on this portal is at our sole discretion. When you set up an individual user account, a "Profile" is created for you, which may include personal information provided by you. JobCritics will update the Profile with information extracted from the Networking Platforms profile used to log in. You may permit us to share information in your Profile with other users, prospective employers, recruiters, and others. Visibility settings of your user profile are under the control of JobCritics, but you may be allowed to change or alter the privacy settings of your profile.
                            </p>
                            <p className="black-class">To create a JobCritics Employer Account, we may require you to provide certain information, including a valid email address. Employers are entirely responsible for maintaining the confidentiality of their login credentials.</p>


                            <p className="black-class">
                                <b>For Employers</b><br />
                                By accessing JobCritics through a third-party platform, you allow us to retrieve and use relevant information from your profile.
                            </p>
                            <p className="black-class">Employers and individual users (All Account Holders) agree to notify us immediately in case of unauthorized use of their JobCritics account. All Account Holders are solely responsible for any and every use of their account. If the usage of your account is not in conformity with these Terms of Use, we may suspend/terminate your account without notice.</p>

                            <h2 className="text-[22px] font-bold mt-4 black-class">Social Signup and Sign In</h2>
                            <p className="black-class">
                                You may register an account and access JobCritics using the login credentials of your account on social/professional media platforms (“Networking Platforms”). If you access JobCritics through a Networking Platform, you allow us to access, store, and make available (if applicable) any information, text, message, tag, and/or other materials that you have made accessible in your Networking Platforms account. Your personally identifiable information may be displayed in your JobCritics profile, subject to the privacy settings set by you on your Networking Platforms account. Please note: your relationship with a Networking Platform is governed solely by your agreement with the Networking Platform, and we repudiate any liability for personally identifiable information provided to us by a Networking Platform in violation of your privacy settings.
                            </p>

                            <h2 className="text-[22px] font-bold mt-4 black-class">Salary Information</h2>
                            <p className="black-class">
                                We may offer salary reviews and calculations, such as personalized estimates, take-home salary for job roles, and salary estimates for certain job openings. While we may allow employers to supplement or replace our salary calculations with their own data, users should understand that, unless indicated otherwise, results do not represent verified salaries, guarantees of actual salaries, or endorsements from employers. The purpose is to provide data to promote transparency and help people make informed career choices. We do not guarantee the accuracy of results, and you are responsible for how you use them.
                            </p>
                            <p className="black-class">
                                We encourage you to supplement JobCritics' salary estimates with other research. If you are an employer and would like to provide feedback to help make our salary estimates more accurate, please contact us at <a href="mailto:support@jobcritics.com" className="text-blue-500 font-medium"> support@jobcritics.com</a>.
                            </p>
                            <p className="black-class">
                                You shall not coerce employees to post reviews. Coercion includes asking employees to provide proof to an employer that they wrote a review, whether or not that proof includes the content of the review itself.
                            </p>
                            <p className="black-class">
                                If you are a multi-level marketing company, you agree that you have reviewed and will abide by the Terms of JobCritics.
                            </p>


                            <h2 className="text-[22px] font-bold mt-4 black-class">Enforcement by JobCritics</h2>
                            <p className="black-class">
                                <b>Removal of Content</b> - While JobCritics has no obligation to do so, we reserve the right to review and delete any Content (or portion thereof) that we believe, in our sole discretion, violates these Terms of Use or other applicable policies posted on JobCritics (including our Community Guidelines), or that we deem inappropriate. If you see any Content on JobCritics that you believe violates our policies, you may report it by clicking on an applicable link below the Content (e.g., "Report" or a "Flag” symbol) or by contacting us at <a href="mailto:support@jobcritics.com" className="text-blue-500 font-medium"> support@jobcritics.com</a>. Once notified, we will review the Content and consider whether to remove it (or a portion thereof). Please note: Our interpretation of our policies and the decision whether or not to edit or remove Content is within our sole discretion. You understand and agree that if we choose not to remove or edit Content that you find objectionable, that decision will not constitute a violation of these Terms of Use or any agreement we have with you.
                            </p>
                            <p className="black-class">
                                <b>Other Enforcement Actions</b> - While we have no obligation to do so, we reserve the right to investigate and take appropriate action in our sole discretion against You if you violate these Terms of Use, including without limitation: removing Content (or portions thereof) from JobCritics; suspending Your rights to use JobCritics; terminating Your membership and account; reporting You to law enforcement, regulatory authorities, or administrative bodies; and taking legal action against You.
                            </p>

                            <h2 className="text-[22px] font-bold mt-4 black-class">Rights to JobCritics Content</h2>
                            <p className="black-class">
                                JobCritics contains Content provided by us and our licensors. We and our licensors (including other users) own and retain all proprietary (including all intellectual property) rights in the Content we each provide, and JobCritics owns and retains all property rights in JobCritics.
                            </p>
                            <p className="black-class">
                                Except as provided in the foregoing, you agree not to:
                            </p>
                            <ul className="list-disc pl-5 black-class">
                                <li>Reproduce, modify, publish, transmit, distribute, publicly perform or display, sell, adapt, or create derivative works based on JobCritics or the Content (excluding Your Content); or</li>
                                <li>Rent, lease, loan, or sell access to JobCritics.</li>
                            </ul>

                            <h2 className="text-[22px] font-bold mt-4 black-class">Use to Be in Conformity with the Purpose</h2>
                            <p className="black-class">
                                The Platform (including Platform and related products) or Service or Product that you subscribe to or use (whether paid for by you or not) is meant for a Purpose and only your exclusive use. Copying, downloading, recreating, sharing passwords, sublicensing, or sharing in any manner not in accordance with these terms is a misuse of the Platform or Service or Product, and the Company reserves its rights to act in such a manner as to protect its loss of revenue or reputation or claim damages, including stopping your service or access and reporting to relevant authorities. If you are found to be copying, misusing, transmitting, or crawling any data, photographs, graphics, or any information available on the Platform or Service or Product for any purpose other than a bonafide Purpose, we reserve the right to take such action as we deem fit, including stopping access and claiming damages.
                            </p>

                            <h2 className="text-[22px] font-bold mt-4 black-class">Accuracy and Correctness of the Information Provided by You</h2>
                            <p className="black-class">
                                WHILST USING THIS PLATFORM, YOU ARE OBLIGATED TO PROVIDE ONLY TRUE AND CORRECT INFORMATION. IN THE CASE OF CREATING A PROFILE, YOU UNDERTAKE TO KEEP THE INFORMATION UP TO DATE AT ALL TIMES.
                            </p>
                            <p className="black-class">
                                WE RESERVE THE RIGHT TO REMOVE/SUSPEND ANY CONTENT OR PROFILE PROVIDING FALSE, INCORRECT, INCOMPLETE, OR OUTDATED INFORMATION OR MASQUERADING/IMPERSONATING SOMEONE ELSE.
                            </p>
                            <p className="black-class">
                                KEEP YOUR CONTACT DETAILS UP TO DATE. WRONG OR FALSE DETAILS CAN BE A CAUSE FOR TERMINATION OF SERVICES. AS A CONSEQUENCE, WE MAY NOT BE ABLE TO REACH YOU.
                            </p>

                            <h2 className="text-[22px] font-bold mt-4 black-class">Security of Login Credentials and Responsibility of Content/Activities</h2>
                            <p className="black-class">
                                DO NOT SHARE PASSWORDS. BE RESPONSIBLE FOR WHAT YOU POST.
                            </p>
                            <p className="black-class">
                                If you have registered on the Platform, the safety and security of your login credentials are your responsibility. Do not share these with anyone.
                            </p>
                            <p className="black-class">
                                A User, through their user identification, shall be solely responsible for carrying out any online or offline transaction involving credit cards/debit cards or other forms of instruments or documents for making such transactions. The Company assumes no responsibility or liability for the improper use of information relating to such usage of credit cards/debit cards used by the subscriber online/offline.
                            </p>
                            <p className="black-class">
                                A User is fully responsible for all activities that occur under their account and any other actions taken in connection with the Platform. It is presumed that all activity happening through a user’s account on the Platform is done after obtaining proper authorizations and lawful permissions as may be applicable.
                            </p>
                            <p className="black-class">
                                The Site may contain links to third-party websites. These links are provided solely as a convenience to You, and the presence of these links should not be considered an endorsement of their contents. If You choose to access these websites, you do so at your own risk.
                            </p>

                            <h2 className="text-[22px] font-bold mt-4 black-class">Content and Liability Disclaimer</h2>
                            <p className="black-class">
                                THE PLATFORM IS AN INTERMEDIARY AS DEFINED UNDER SUB-CLAUSE (W) OF SECTION 2 OF THE INFORMATION TECHNOLOGY ACT, 2000 OF INDIA.
                            </p>
                            <p className="black-class">
                                The Company shall not be responsible for any errors or omissions contained on any Company Platform or mobile site or application and reserves the right to make changes at any time without notice. Mention of non-Company products or services is provided for informational purposes only and constitutes neither an endorsement nor a recommendation by the Company. All Company and third-party information provided on any Company Platform or mobile site or application is provided on an "AS IS" basis.
                            </p>
                            <p className="black-class">
                                Views expressed by users are their own. The Company does not endorse the same. No claim is made as to the accuracy and correctness of the information on the site. If any inaccuracy or improper content is sighted on the Platform, please report it. If the content is found to be not in accordance with these Terms of Use, we reserve the right to terminate or restrict your use or access to the Site (or any part thereof) and/or take down any content uploaded by You without notice.
                            </p>

                            <p className="black-class">
                                COMPANY DISCLAIMS ALL WARRANTIES, EXPRESSED OR IMPLIED, WITH REGARD TO ANY INFORMATION (INCLUDING ANY SOFTWARE, PRODUCTS, OR SERVICES) PROVIDED ON ANY COMPANY WEBSITE/MOBILE SITE/APPLICATION/SOFTWARE, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
                            </p>

                            <h2 className="text-[22px] font-bold mt-4 black-class">Make Your Own Decision</h2>
                            <p className="black-class">
                                DO AN INDEPENDENT CHECK BEFORE PROCEEDING WITH A TRANSACTION. THE DATA ON THE PLATFORM IS ONLY FOR INFORMATIONAL PURPOSES.
                            </p>
                            <p className="black-class">
                                THE COMPANY DOES NOT GUARANTEE THE AUTHENTICITY, NATURE, OR QUANTUM OF THE RESPONSES OR THAT THERE WILL BE ANY RESPONSE AT ALL.
                            </p>
                            <p className="black-class">
                                Views expressed by users are their own. The Company does not endorse the same. No claim is made as to the accuracy and correctness of the information on the site. If any inaccuracy or improper content is sighted on the Platform, please report it. If the content is found to be not in accordance with these Terms of Use, we reserve the right to terminate or restrict your use or access to the Site (or any part thereof) and/or take down any content uploaded by You without notice.
                            </p>

                            <p className="black-class">
                                If you are making purchasing or planning decisions, whether personal or business, you should look at an actual demonstration model of any product/service you are considering before making your purchasing/subscription or planning decisions (contact a supplier, store, service center, or manufacturer regarding looking at any demonstration units). All decisions made are entirely your prerogative, and the Company does not claim to offer any advice, either legal or financial, nor does it provide any guarantees or warranties.
                            </p>

                            <h2 className="text-[22px] font-bold mt-4 black-class">Privacy</h2>
                            <p className="black-class">
                                THE PLATFORM IS AN INTERMEDIARY AS DEFINED UNDER SUB-CLAUSE (W) OF SECTION 2 OF THE INFORMATION TECHNOLOGY ACT, 2000 OF INDIA.
                            </p>
                            <p className="black-class">
                                PLEASE USE THE PRIVACY SETTINGS AVAILABLE ON THE PLATFORM TO PROTECT YOUR INFORMATION.
                            </p>
                            <p className="black-class">
                                The Privacy Policy of the Platform explains how we may use your personal data. We will at all times respect and ensure adherence to the Privacy Policy. Additionally, various settings are provided to help you control the manner in which others may view your information as chosen by you to be displayed on your profile and the manner in which you may have chosen to be contacted. Any feedback provided by a User shall be deemed non-confidential to the User.
                            </p>
                            <p className="black-class">
                                When You share, upload, or store information, others may be able to view, copy, or download such information.
                            </p>

                            <h2 className="text-[22px] font-bold mt-4 black-class">Payment Terms & Refund Policy</h2>
                            <p className="black-class">
                                HONOR YOUR PAYMENT OBLIGATIONS. REFUNDS OR THEIR TIMELINES ARE NOT GUARANTEED.
                            </p>
                            <p className="black-class">
                                Payments for the services offered by the Platform shall be on a 100% advance basis. The payment for service once subscribed to by You is not refundable, and any amount paid shall stand appropriated. Refunds, if any, will be at the sole discretion of the Company. The Company offers no guarantees whatsoever for the accuracy or timeliness of refunds reaching the Customer’s card/bank accounts. This is due to the multiplicity of organizations involved in processing online transactions, problems with Internet infrastructure, and working days/holidays of financial institutions.
                            </p>
                            <p className="black-class">
                                User acknowledges and agrees that the Company/Platform, at its sole discretion and without prejudice to other rights and remedies that it may have under applicable laws, shall be entitled to set off the amount paid or payable by a subscriber/user against any amount(s) payable by the User to the Company under any other agreement or commercial relationship towards other products/services. The Company gives no guarantees of server uptime or applications working properly. All is on a best-effort basis, and liability is limited to a refund of the amount only. We undertake no liability for free services.
                            </p>
                            <p className="black-class">
                                For completing online transactions involving payments, a user is directed to a Payment Gateway. The Company does not store or keep credit card data. Since the transaction happens on a third-party network not controlled by the Company, during or once a credit card transaction has been completed, the payment information is not accessible to anyone at the Company after completion of the online transaction at the Payment Gateway, ensuring maximum security.
                            </p>
                            <p className="black-class">
                                The Company shall not be liable for any loss or damage sustained by reason of any disclosure (inadvertent or otherwise) of any information concerning the user's account and/or information relating to or regarding online transactions using credit cards/debit cards and/or their verification process and particulars, nor for any error, omission, or inaccuracy with respect to any information so disclosed and used, whether or not in pursuance of a legal process or otherwise.
                            </p>

                            <h2 className="text-[22px] font-bold mt-4 black-class">Amendment to These Terms of Use</h2>
                            <p className="black-class">
                                THE COMPANY RESERVES ITS RIGHT TO AMEND/ALTER OR CHANGE ALL OR ANY DISCLAIMERS OR TERMS OF AGREEMENTS AT ANY TIME WITHOUT PRIOR NOTICE.
                            </p>
                            <p className="black-class">
                                You are advised to review the modified Terms of Use periodically to be aware of such modifications. Your continued access or use of the Site shall be deemed conclusive proof of your acceptance of these Terms of Use, as amended/modified from time to time.
                            </p>

                            <h2 className="text-[22px] font-bold mt-4 black-class">Intellectual Property Rights</h2>
                            <p className="black-class">
                                RESPECT OUR PROPRIETARY RIGHTS AND BRANDS.
                            </p>
                            <p className="black-class">
                                JobCritics® and its logos are trademarks (whether registered or not) of JobCritics.com. The works of authorship, original content on this site, trademarks, logos, and service marks ("Marks") displayed on JobCritics are our property or the property of third parties. You are not permitted to use these Marks without our prior written consent or the consent of the third party that owns the Mark.
                            </p>
                            <p className="black-class">
                                All title and intellectual property rights in and to the Platform, and any copies You are permitted to make herein, are owned by the Company and are protected by applicable laws. You are granted a limited license to use and access the Platform for the Purpose, provided that You are at all times in compliance with these Terms of Use. No rights are granted to You other than a limited permission to use the Platform upon the terms expressly set forth in these Terms of Use. The structure, sequence, organization, and source code of the Platform are valuable trade secrets of the Company.
                            </p>
                            <p className="black-class">
                                By opting to access the Site and use the services, you hereby acknowledge and agree that any information or material provided to the Company will be deemed NOT to be confidential and proprietary to You. By providing the Company any information or other material, You grant the Company a non-exclusive, unrestricted, irrevocable, perpetual, royalty-free, worldwide, assignable, transferrable, sublicensable right and license, in all formats or media, whether now known or hereafter devised or discovered, to use, reproduce, display, transmit, translate, rent, sell, modify, disclose, publish, create derivative works from, and distribute that material or information. You also agree that the Company is free to use any ideas, concepts, know-how, or techniques that You send us for any purpose. You further agree to indemnify, defend, and hold the Company harmless from any liability arising due to the use or distribution of any such material or information.
                            </p>
                            <p className="black-class">
                                The above provisions and rights granted regarding title and intellectual property rights associated with the Platform shall survive termination of these Terms of Use.
                            </p>
                            <h2 className="text-[22px] font-bold mt-4 black-class">Local Laws</h2>
                            <p className="black-class">
                                The Company controls and operates this Platform from its headquarters in Chandigarh and makes no representation that the materials on the Platform are appropriate or available for use in other locations. If you use this Website from other locations, you are responsible for compliance with applicable local laws, including but not limited to the export and import regulations of other countries.
                            </p>
                            <h2 className="text-[22px] font-bold mt-4 black-class">Indemnification</h2>
                            <p className="black-class">
                                BY ACCEPTING THESE TERMS OF USE, YOU AGREE TO INDEMNIFY AND OTHERWISE HOLD HARMLESS THE COMPANY, ITS DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SUBSIDIARIES, AFFILIATES, AND OTHER PARTNERS FROM ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES ARISING OUT OF, RELATING TO, OR RESULTING FROM YOUR USE OF THE PLATFORM, INCLUDING BUT NOT LIMITED TO INFORMATION PROVIDED BY YOU OR ANY OTHER MATTER RELATING TO THE PLATFORM. ANY REFERENCE TO DUTIES AND TAXES ETC. IN THESE TERMS OF USE SHALL INCLUDE GOODS AND SERVICES TAX (GST) FROM THE DATE GST LAW IS IMPLEMENTED IN INDIA. ANY ADDITIONAL TAX LIABILITY ARISING ON ACCOUNT OF THE INTRODUCTION OF GST (WHETHER ON ACCOUNT OF AN INCREASE IN RATE OR ANY CHANGE BROUGHT IN BY THE NEW TAX REGIME) WOULD BE RECOVERED OVER AND ABOVE THE AGREED CONTRACT PRICE/SERVICE FEE.
                            </p>

                            <h2 className="text-[22px] font-bold mt-4 black-class">Restrictions/Prohibitions</h2>
                            <p className="black-class">
                                YOU REPRESENT, WARRANT, AND COVENANT THAT YOUR USE OF THE PLATFORM SHALL NOT BE DONE IN A MANNER SO AS TO:
                            </p>
                            <ul className="list-disc pl-5 black-class">
                                <li>Access the Platform for purposes of extracting content to be used for training a machine learning or AI model without the express prior written permission.</li>
                                <li>Violate any applicable local, provincial, state, national, or international law, statute, ordinance, rule, or regulation.</li>
                                <li>Interfere with or disrupt computer networks connected to the Platform.</li>
                                <li>Impersonate any other person or entity or make any misrepresentation as to your employment by or affiliation with any other person or entity.</li>
                                <li>Provide information belonging to another person to which you have no right.</li>
                                <li>Forge headers or manipulate identifiers to disguise the origin of any user information.</li>
                                <li>Interfere with or disrupt the use of the Platform by any other user, nor "stalk," threaten, or harass another user.</li>
                                <li>Use the Platform to gain unauthorized entry or access to the computer systems of others.</li>
                                <li>Reproduce, copy, modify, sell, store, distribute, or otherwise exploit for any commercial purposes the Platform or any component thereof.</li>
                                <li>Use content from the Site for derivative works with a commercial motive without prior written consent of the Company.</li>
                                <li>Use any device, software, or routine to interfere or attempt to interfere with the proper working of the Platform.</li>
                                <li>Impose an unreasonable or disproportionately large load on the Platform infrastructure.</li>
                                <li>Spam the Platform/Company by indiscriminately and repeatedly posting content or forwarding mail that may be considered spam.</li>
                                <li>Access data not intended for you or log into a server or account that you are not authorized to access.</li>
                                <li>Reverse engineer, decompile, disassemble, decipher, or otherwise attempt to derive the source code for the Site or any related technology or any part thereof.</li>
                                <li>Engage in “framing,” “mirroring,” or otherwise simulating the appearance or function of the Site.</li>
                                <li>Attempt to probe, scan, or test the vulnerability of a system or network.</li>
                                <li>Use automated means to crawl the Platform and scrape content from the Platform.</li>
                                <li>Access the Site except through the interfaces expressly provided by the Company.</li>
                                <li>Attempt or breach security or authentication measures without proper authorization.</li>
                                <li>Provide deep links into this Platform without prior permission of the Company.</li>
                                <li>Extract data from this Platform using any automated process such as spiders, crawlers, etc., or through any manual process for a purpose not authorized in writing.</li>
                                <li>Upload, post, email, transmit, or otherwise make available either directly or indirectly any unsolicited bulk e-mail or unsolicited commercial e-mail.</li>
                                <li>Sublicense, assign, or transfer the License, as any attempt at such sublicense, assignment, or transfer is void.</li>
                                <li>Host, modify, upload, post, transmit, publish, or distribute any material or information:</li>
                                <ul className="list-disc pl-5 black-class">
                                    <li>For which you do not have all necessary rights and licenses.</li>
                                    <li>Which infringes, violates, breaches, or otherwise contravenes the rights of any third party, including any copyright, trademark, patent, rights of privacy or publicity, or any other proprietary right.</li>
                                    <li>Which contains a computer virus or other code, files, or programs intending to disrupt or interfere with the functioning of the Platform or other computer systems.</li>
                                    <li>That is grossly harmful, harassing on the basis of gender, invasive of another's privacy, hateful, disparaging, relating to money laundering or unlawful, or which may be perceived as harmful, threatening, abusive, harassing, libellous, vulgar, obscene, or racially, ethnically, or otherwise unlawful.</li>
                                    <li>Which constitutes or encourages conduct that would constitute a criminal offence, give rise to other liability, or otherwise violate applicable law.</li>
                                    <li>That deceives or misleads the addressee about the origin of such messages or knowingly and intentionally communicates any misinformation or information that is grossly false and untrue or misleading in nature.</li>
                                    <li>That belongs to another person and to which the user does not have any right.</li>
                                    <li>That harms minors in any way.</li>
                                    <li>That threatens the unity, integrity, defense, security, or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting to any other nation.</li>

                                </ul>
                            </ul>
                            <h2 className="text-[22px] font-bold mt-4 black-class">Limitation of Liability</h2>
                            <p className="black-class">
                            YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE COMPANY SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES (EVEN IF THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES). THE COMPANY PROVIDES NO GUARANTEES OF SERVER UPTIME OR APPLICATIONS WORKING PROPERLY. IN NO EVENT WILL THE COMPANY’S AGGREGATE LIABILITY TO YOU EXCEED THE AMOUNT OF LICENSING FEES PAID BY YOU TO THE COMPANY. THESE LIMITATIONS AND EXCLUSIONS WILL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF DAMAGES AND/OR EXCLUSIONS OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
                            </p>

                            <h2 className="text-[22px] font-bold mt-4 black-class">Termination/Suspension</h2>
                            <p className="black-class">
                            THE COMPANY MAY, WITHOUT NOTICE IN ITS SOLE DISCRETION AND AT ANY TIME, TERMINATE OR RESTRICT YOUR USE OR ACCESS TO THE PLATFORM (OR ANY PART THEREOF) FOR ANY REASON, INCLUDING, WITHOUT LIMITATION, IF THE COMPANY BELIEVES YOU HAVE VIOLATED OR ACTED INCONSISTENTLY WITH THE LETTER OR SPIRIT OF THESE TERMS OF USE.
                            </p>

                            <h2 className="text-[22px] font-bold mt-4 black-class">Dispute Settlement</h2>
                            <p className="black-class">
                            If any dispute arises between a user/users and the Company arising out of the use of the Platform or thereafter, in connection with the validity, interpretation, implementation, or alleged breach of any provision of these Terms of Use, the dispute shall be referred to a sole arbitrator who shall be an independent and neutral third party identified by the Company. The decision of the arbitrator shall be final and binding on both parties to the dispute. The place of arbitration shall be Chandigarh, India. The Arbitration & Conciliation Act, 1996, as amended, shall govern the arbitration proceedings.
                            </p>
                            <p className="black-class">The Company will not be a party to any legal proceedings between a User (e.g., a subscriber) and a party contacted through the site. If the Company is made a party in any legal proceedings, costs will be recovered from the party on whose behest the Company is involved in the matter, to which it otherwise has no control. The Company, however, will abide by any court order served on it through due process.</p>

                            <h2 className="text-[22px] font-bold mt-4 black-class">Governing Law and Jurisdiction</h2>
                            <p className="black-class">
                            These Terms of Use shall be governed by the laws of the Republic of India. The exclusive forum for any disputes arising out of or relating to these Terms of Use shall be a court of law located in Chandigarh, India.
                            </p>
                        
                            <h2 className="text-[22px] font-bold mt-4 black-class">Entire Agreement</h2>
                            <p className="black-class">THESE TERMS OF USE, AS AMENDED FROM TIME TO TIME, CONSTITUTE THE ENTIRE AGREEMENT BETWEEN YOU AND THE COMPANY REGARDING THE PLATFORM.</p>
                            <p className="black-class">These Terms of Use supersede all terms and conditions contained in any purchase order, order acknowledgment form, invoice, or other business form submitted by You.</p> 
                            <p className="black-class">No advertisements, catalogues, or other publications or statements, whether written or oral, regarding the performance of the Platform permitted under these Terms of Use shall form part of these Terms of Use.</p>

                            <h2 className="text-[22px] font-bold mt-4 black-class">Severability</h2>
                            <p className="black-class">
                            IF ANY PROVISION OF THESE TERMS OF USE IS HELD TO BE INVALID OR UNENFORCEABLE BY A COURT OF COMPETENT JURISDICTION, SUCH PROVISION WILL BE CHANGED AND INTERPRETED TO BEST ACCOMPLISH THE OBJECTIVES OF THE ORIGINAL PROVISION TO THE FULLEST EXTENT ALLOWED BY LAW, AND THE REMAINING PROVISIONS OF THESE TERMS OF USE SHALL REMAIN IN FULL FORCE AND EFFECT.
                            </p>

                            <h2 className="text-[22px] font-bold mt-4 black-class">Assignment and Delegation</h2>
                            <p className="black-class">
                            You may not assign or delegate your rights under these Terms of Use or the limited license extended to you. Any assignment and/or delegation of these Terms of Use or any sublicensing by you will be null and void. If any case of this nature is brought to our notice, in addition to any other remedies that we may have under the law, we reserve our rights to claim damages and seek an injunction against You.
                            </p>

                            <h2 className="text-[22px] font-bold mt-4 black-class">Availability of Services</h2>
                            <p className="black-class">
                            Services are provided on a best-efforts basis on an AS IS and AS AVAILABLE basis. However, there are no server uptime guarantees.
                            </p>
                            <p className="black-class">Any regulations, guidelines, legislations, or lawful orders of a court or quasi-judicial body may require us to change, alter, or stop our services. Similarly, any force majeure events beyond our reasonable control may cause an interruption in the services.</p>

                            <h2 className="text-[22px] font-bold mt-4 black-class">Statement of Compliance with Information Technology (Intermediary Guidelines) Rules 2021</h2>
                            <p className="black-class">
                            THE USE AND ACCESS OF THE PLATFORM OR ANY TOOL/APPLICATION/SOFTWARE SHALL BE SUBJECT TO THESE TERMS OF USE AND THE PRIVACY POLICY.
                            </p>
                            <p className="black-class">USING/ACCESSING ANY PORTION OF THE PLATFORM IN ANY MANNER AGAINST THE EXPRESSLY PROHIBITED ABOVE OR NON-COMPLIANCE WITH THESE TERMS OF USE AND THE PRIVACY POLICY REGULATING THIS PLATFORM WILL PROVIDE US THE RIGHT TO IMMEDIATELY TERMINATE ACCESS OR USAGE RIGHTS AND REMOVE ANY CONTENT THAT IS NON-COMPLIANT WITH THESE TERMS OF USE.</p>
                            <p className="black-class">
                            THE COMPANY HAS APPOINTED A GRIEVANCE OFFICER TO RECEIVE AND DISPOSE OF ANY COMPLAINTS THAT YOU MAY HAVE REGARDING CONTENT ON THE PLATFORM. THE GRIEVANCE OFFICER MAY BE CONTACTED USING THE INTERFACE AVAILABLE HERE.
                            </p>

                            <h2 className="text-[22px] font-bold mt-4 black-class">How You May Contact Us</h2>
                            <p className="black-class">
                            YOU MAY CONTACT US AT <a href="mailto:support@jobcritics.com" className="text-blue-500 font-medium"> support@jobcritics.com</a>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Footer */}
            <FooterStyle theme="light" className="bg-black text-[#828282]" />
        </div>
    );
};

export default TermPolicy;