import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";


const AuthSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");

    if (token) {
      localStorage.setItem("jwt_token_jobcritic", token);
      const decoded = jwtDecode(token);
      console.log("User Info:", decoded);
      navigate("/employee_detail");
    }
  }, [navigate]);

  return <h2>Authenticating...</h2>;
};

export default AuthSuccess;
