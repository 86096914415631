import React, { memo, useEffect, useState } from "react";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import PropTypes from "prop-types";

const StickTab = ({ data, sectionRefs }) => {
    const [activeTab, setActiveTab] = useState(0);
    const [isScrolling, setIsScrolling] = useState(false);

    const handleTabClick = (index, refKey) => {
        setIsScrolling(true);

        if (sectionRefs[refKey] && sectionRefs[refKey].current) {
        //     sectionRefs[refKey].current.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
        //    // setActiveTab(index);
        //    //window.scrollBy({ top: -100, behavior: "smooth" });
        //    setTimeout(() => {
        //         window.scrollBy({ top: -100, behavior: "smooth" }); // Adjust offset from top
        //     }, 100); // Slight delay to allow smooth scrolling

            const offset = 40; // Adjust this value to set the offset from the top
            const elementPosition = sectionRefs[refKey].current.getBoundingClientRect().top + window.scrollY;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth",
            });
        }



        setTimeout(() => {
            setIsScrolling(false);
        }, 500);
    };

    useEffect(() => {
        if (isScrolling) return;

        // const handleScroll = () => {
        //     let newActiveTab = 0;

        //     data.forEach((tab, index) => {
        //         const ref = sectionRefs[tab.refKey];
        //         if (ref && ref.current) {
        //             const rect = ref.current.getBoundingClientRect();
        //             if (rect.top >= 0 && rect.top <= window.innerHeight / 3) {
        //                 newActiveTab = index;
        //             }
        //         }
        //     });

        //     setActiveTab(newActiveTab);
        // };
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            data.forEach((tab, index) => {
                const section = sectionRefs[tab.refKey].current;
              if (section) {
                const { offsetTop, offsetHeight } = section;
                //console.log(section);
                if (scrollPosition >= offsetTop - 50 && scrollPosition < offsetTop + offsetHeight - 50) {
                    setActiveTab(index);
                }
              }
            });
          };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <Tab.Container  id="left-tabs-example" defaultActiveKey={data[0].refKey}>
            <Row className="tab-style-07">
                <Col lg={12} className="p-0 xs:!px-[15px]">
                    <Nav className="justify-between uppercase font-serif font-medium text-center cursor-pointer  md:flex-nowrap md:mb-[72px] sm:mb-[66px] sm:border-b-0">
                        {data.map((item, i) => (
                            <Nav.Item style={{padding:0}} key={i}>
                                <Nav.Link
                                    className={`block text-darkgray py-[30px] px-[40px] mb-0 transition-all duration-300 ${
                                        activeTab === i ? "border-b-[3px] border-solid" : "border-transparent"
                                    }`}
                                    eventKey={i}
                                    onClick={() => handleTabClick(i, item.refKey)}
                                >
                                    {item.tabTitle}
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                        { data.length>3 && (<Nav.Item>
                            <Nav.Link className="block text-darkgray py-[30px] px-[40px] mb-0 transition-all duration-300 cursor-pointer">
                                <button className="btn-explore-white px-4 py-2 font-bold rounded-md ml-4">
                                    <span className="mr-2">Download Report</span>
                                </button>
                            </Nav.Link>
                        </Nav.Item>) }

                    </Nav>
                </Col>
            </Row>
        </Tab.Container>
    );
};

StickTab.propTypes = {
    data: PropTypes.array.isRequired,
    sectionRefs: PropTypes.object.isRequired,
    isSticky: PropTypes.bool.isRequired, // Added prop to control button visibility
};

export default memo(StickTab);
