import React, { useState, useEffect } from "react";
import { Dropdown, Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { getStarColorClass, getRatingLabel } from '../../Functions/RatingLabelColor';

const FilterModal = ({ show, handleClose , filtertype, filterMethod ,filterValData, filterData }) => {


    const [selectedRating, setSelectedRating] = useState(null);
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [visibleRows, setVisibleRows] = useState(2); // Pehle 2 rows dikhao
    const [itemsPerRow, setItemsPerRow] = useState(9); // Default value
    const [selectedProfessionals, setSelectedProfessionals] = useState([]);
    const [visibleProfessionalRows, setVisibleProfessionalRows] = useState(2);
    const [selectedProfessionalIDs, setSelectedProfessionalIDs] = useState([]);
    const [selectedAcademicIDs, setSelectedAcademicIDs] = useState([]);
    const [selectedAcademics, setSelectedAcademics] = useState([]);
    const [visibleAcademicRows, setVisibleAcademicRows] = useState(2);
    const [filterNData, setFilterNData] = useState(filterData);
    

    useEffect(() => {
        setFilterNData(filterData);
      }, [filterData]);
    
    
    const [ratings,setRatings]= useState([]);
    const [organizations,setOrganizations]= useState([]);
    const [professionals,setProfessionals]= useState([]);
    const [acadamincs,setAcadamincs]= useState([]);
    
    useEffect(() => {
       console.log('checkdata',filterValData);

       if(filterValData && filterValData.ratings)
            setRatings(filterValData.ratings);
        if(filterValData && filterValData.organizations)
            setOrganizations(filterValData.organizations);
        if(filterValData && filterValData.professionals)
            setProfessionals(filterValData.professionals)
        if(filterValData && filterValData.professionals)
            setProfessionals(filterValData.professionals)
        if(filterValData && filterValData.acadamincs)
            setAcadamincs(filterValData.acadamincs)

    }, [filterValData]);
    

    const academicList = Array.from({ length: 5 }, (_, i) => ({
        id: i + 1,
        name: `Academic ${i + 1}`,
    }));

    const toggleAcademicSelection = (item) => {
        setSelectedAcademics((prev) =>
            prev.some((i) => i.id === item.id) ? prev.filter((i) => i.id !== item.id) : [...prev, item]
        );

        setSelectedAcademicIDs((prev) =>
            prev.includes(item.id) ? prev.filter((id) => id !== item.id) : [...prev, item.id]
        );

        console.log("Selected Academic ID:", item.id, "Name:", item.name);
    };

    // ✅ Dummy Professional Data
    const professionalList = Array.from({ length: 5 }, (_, i) => ({
        id: i + 1,
        name: `Professional ${i + 1}`,
    }));

    const companyAndSchools = [
        { id: 1, name: "Google" },
        { id: 2, name: "Microsoft" },
        { id: 3, name: "Harvard" },
        { id: 4, name: "Oxford" },
        { id: 5, name: "Tesla" },
        { id: 6, name: "Apple" },
        { id: 7, name: "Amazon" },
        { id: 8, name: "Facebook" },
        { id: 9, name: "Stanford" },
        { id: 10, name: "MIT" },
        { id: 11, name: "Intel" },
        { id: 12, name: "IBM" },
        { id: 13, name: "Adobe" },
        { id: 14, name: "Netflix" },
        { id: 15, name: "SpaceX" },
        { id: 16, name: "Uber" },
        { id: 17, name: "Airbnb" },
        { id: 18, name: "Twitter" },
        { id: 19, name: "Snapchat" },
        { id: 20, name: "TikTok" },
        { id: 21, name: "Nvidia" },
        { id: 22, name: "AMD" },
        { id: 23, name: "Qualcomm" },
        { id: 24, name: "Samsung" },
        { id: 25, name: "Sony" },
        { id: 26, name: "LG" },
        { id: 27, name: "Dell" },
        { id: 28, name: "HP" },
        { id: 29, name: "Cisco" },
        { id: 30, name: "Oracle" },
        { id: 31, name: "Salesforce" },
        { id: 32, name: "Zoom" },
        { id: 33, name: "Pinterest" },
        { id: 34, name: "PayPal" },
        { id: 35, name: "Stripe" },
        { id: 36, name: "Shopify" },
        { id: 37, name: "Reddit" },
        { id: 38, name: "Dropbox" },
        { id: 39, name: "Slack" },
        { id: 40, name: "Discord" },
        { id: 41, name: "Coursera" },
        { id: 42, name: "Udemy" },
        { id: 43, name: "LinkedIn" },
        { id: 44, name: "Tesla University" },
        { id: 45, name: "Facebook Academy" },
        { id: 46, name: "Amazon Institute" },
        { id: 47, name: "YouTube Learning" },
        { id: 48, name: "TikTok School" },
        { id: 49, name: "OpenAI Research" },
        { id: 50, name: "ChatGPT University" },
    ];

    const resetFilters = () => {
        setSelectedRating(null);
        setSelectedTypes([]);
        setSelectedItems([]);
        setSelectedAcademics([]);
        setSelectedAcademicIDs([]);
        setSelectedProfessionals([]);
        setSelectedProfessionalIDs([]);
    };
    
 
    useEffect(() => {
        const updateItemsPerRow = () => {
            const width = window.innerWidth;
            if (width >= 1200) setItemsPerRow(5);
            else if (width >= 768) setItemsPerRow(4);
            else setItemsPerRow(2);
        };

        updateItemsPerRow();
        window.addEventListener("resize", updateItemsPerRow);
        return () => window.removeEventListener("resize", updateItemsPerRow);
    }, []);

    // ✅ Selection Function (ID + Name Select Hoga)
    const toggleProfessionalSelection = (item) => {
        setSelectedProfessionals((prev) =>
            prev.some((i) => i.id === item.id) ? prev.filter((i) => i.id !== item.id) : [...prev, item]
        );

        setSelectedProfessionalIDs((prev) =>
            prev.includes(item.id) ? prev.filter((id) => id !== item.id) : [...prev, item.id]
        );

        console.log("Selected ID:", item.id, "Name:", item.name);
    };

    const applyFilters = () => {
        // const filters = {
        //     rating: selectedRating,
        //     types: selectedTypes,
        //     items: selectedItems,
        //     professionals: selectedProfessionals,
        //     professionalIDs: selectedProfessionalIDs,
        //     academics: selectedAcademics,
        //     academicIDs: selectedAcademicIDs,
        // };
        const newFilterData = {...filterData,acadaminc:selectedAcademicIDs,professional:selectedProfessionalIDs,organization:selectedItems,ratings:filterNData.ratings}
        filterMethod(newFilterData);
        //console.log("Applied Filters:", filterNData);
        //console.log("Applied Filters--:", filters);
    
        // ✅ Yahan API call bhi kar sakte hain agar data backend bhejna ho
        // axios.post('/api/filters', filters).then(response => console.log(response));
    
        handleClose(); // Modal close karna
    };
    const [selectedRatings, setSelectedRatings] = useState([]);

    const toggleRating = (rating) => {
        setSelectedRatings((prev) => {
            const updatedRatings = prev.includes(rating.rate)
                ? prev.filter((r) => r !== rating.rate) // Remove if already selected
                : [...prev, rating.rate]; // Add if not selected
            
            console.log("Updated Selected Ratings:", updatedRatings); // ✅ Check updated state
            if(updatedRatings.length>0){
                const ratingsVal = updatedRatings.join(',');
                const typeFilterData = {...filterNData,ratings:ratingsVal};

                setFilterNData(typeFilterData);
            }
            //handleClose();
            return updatedRatings;
        });
    };
    const toggleOrganization = (orgid) =>{
        setSelectedItems((prev) => {
            const updatedOrg = prev.includes(orgid)
                ? prev.filter((r) => r.id !== orgid.id) // Remove if already selected
                : [...prev, orgid]; // Add if not selected
            
            console.log("Updated Selected org:", updatedOrg); // ✅ Check updated state
            // if(updatedOrg.length>0){
            //     const orgVal = updatedOrg.join(',');
            //     const typeFilterData = {...filterNData,ratings:ratingsVal};

            //     setFilterNData(typeFilterData);
            // }
            //handleClose();
            return updatedOrg;
        });
    };
    
    
    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title className="text-lg font-bold text-gray-900">Filter Reviews</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h6 className="font-medium text-[18px] text-[#000] mb-1 leading-[1.4]">Ratings</h6>
                <div className="d-flex gap-2 flex-wrap">
            {ratings.map((rating,index) => (
                <span
                    key={index}
                    className={`flex items-center px-3 py-1 rounded-full text-xs font-medium border cursor-pointer ${
                        selectedRatings.includes(rating.rate)
                            ? "border-blue-500 bg-gray-100 text-blue-700"
                            : "border-gray-300 bg-white text-black"
                    }`}
                    onClick={() => toggleRating(rating)}
                >
                    {/* Star Icons */}
                    {[...Array(5)].map((_, starIdx) => (
                        <i
                            key={starIdx}
                            className={`fas fa-star mr-1 ${
                                starIdx < rating.rate ? getStarColorClass(rating.rate) : "text-gray-300"
                            }`}
                        ></i>
                    ))}
                    <span className="ml-2 text-gray-700">({rating.total})</span>
                </span>
            ))}
        </div>

                <h6 className="font-medium text-[18px] text-[#000] mb-1 mt-4 leading-[1.4]">Organizations</h6>
                <div className="d-flex gap-2 flex-wrap">
                    {organizations.map((item) => (
                        <span
                            key={item.id}
                            className={`flex items-center px-3 py-1 rounded-full text-xs font-medium border cursor-pointer ${selectedItems.includes(item) ? "border-blue-500 bg-gray-100 text-blue-700" : "border-gray-300 bg-white text-black"
                                }`}
                            onClick={() => {
                                toggleOrganization(item);
                                console.log("Selected Origination ID:", item.id, "Name:", item.name);
                            }}
                        >
                            <i className="fas fa-building mr-1 text-gray-500"></i>
                            {item.name}
                        </span>
                    ))}

                    {/* Load More Button */}
                    {visibleRows * itemsPerRow < organizations.length && (
                        <button
                            className="text-black font-bold text-sm cursor-pointer hover:bg-gray-200 px-2 py-1 rounded"
                            onClick={() => setVisibleRows((prev) => prev + 1)}
                        >
                            Load More
                        </button>
                    )}
                </div>

                <h6 className="font-medium text-[18px] text-[#000] mb-1 mt-4 leading-[1.4]">Professional Skills</h6>
                <div className="d-flex gap-2 flex-wrap">
                    {professionals.map((item) => (
                        <span
                            key={item.id}
                            className={`flex items-center px-3 py-1 rounded-full text-xs font-medium border cursor-pointer ${selectedProfessionalIDs.includes(item.id) ? "border-blue-500 bg-gray-100 text-blue-700" : "border-gray-300 bg-white text-black"
                                }`}
                            onClick={() => toggleProfessionalSelection(item)}
                        >
                            <i className="fas fa-user-tie mr-1 text-gray-500"></i>
                            {item.name}
                        </span>
                    ))}
                </div>

              

                <h6 className="font-medium text-[18px] text-[#000] mb-1 mt-4 leading-[1.4]">Academic Skills</h6>
                <div className="d-flex gap-2 flex-wrap">
                    {acadamincs.map((item) => (
                        <span
                            key={item.id}
                            className={`flex items-center px-3 py-1 rounded-full text-xs font-medium border cursor-pointer ${selectedAcademicIDs.includes(item.id) ? "border-blue-500 bg-gray-100 text-blue-700" : "border-gray-300 bg-white text-black"
                                }`}
                            onClick={() => toggleAcademicSelection(item)}
                        >
                            <i className="fas fa-graduation-cap mr-1 text-gray-500"></i>
                            {item.name}
                        </span>
                    ))}
                </div>

                

            </Modal.Body>
            <Modal.Footer className="flex justify-between items-center border-t pt-3">
                <Button
                    className="text-black underline bg-transparent border-none shadow-none px-0  hover:decoration-blue-600 h-c-r"
                    onClick={resetFilters}
                >
                    Reset
                </Button>

                <Button
                    className="btn-explore-white"
                    onClick={applyFilters} 
                >
                    Apply
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default FilterModal;
