import React, { useState , useEffect} from "react";
import { useParams } from "react-router-dom";
import { Col, Container, Row, Form } from "react-bootstrap";
import { Formik, Field, Form as FormikForm, ErrorMessage } from "formik";
import * as Yup from "yup";
import RatingSubmit from "./RatingSubmit";
import MainHeader from "../../Pages/Header/MainHeader";
import FooterStyle from "../Footer/Footer";
import { IMAGE_PATHS,base64Encode,base64Decode ,DOMAIN_URL} from "../../Functions/Constant";
import API from '../../Api/Api';

const ReviewForm = () => {
    const { id } = useParams();
    const [reviewId, setReviewId] = useState("");
    const [review, setReview] = useState(null);
    const [categorySkills, setCategorySkills] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [ratingDataVal, setRatingDataVal] = useState([]);
    const fetchDraftReview = async (id) => {
        try {
            const payload = {
                review_id: id,
            };
            const response = await API.post(`/user/getuser-for-rating`,payload);
            if (response.users.length > 0) {
                const user = response.users[0];
                setReview(user);
                console.log("user:", user);
                fetchSkills(user.type);
            }
            console.log("Response:", response);
            // if (response.success) {
            //     setReview(response.review);
            // } else {
            //     setError("Review not found.");
            // }
        } catch (error) {
            //setError("Failed to load review.");
            console.error("Error fetching review:", error);
        } finally {
            //setLoading(false);
        }
    };
    const fetchSkills = async (type) => {
        try {
            if(type == 1){
                const response = await API.get(`/autocomplete/skills/professional`);
                if (response) {
                    setCategorySkills(response);
                } else {
                    setError("Skills not found.");
                }
            }else{
                const response = await API.get(`/autocomplete/skills/academic`);
                if (response) {
                    setCategorySkills(response);
                } else {
                    setError("Skills not found.");
                }
            }
            
        } catch (error) {
            setError("Failed to load skills.");
            console.error("Error fetching skills:", error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {

        setReviewId(base64Decode(id));
        fetchDraftReview(base64Decode(id));
        console.log("Review ID:", id,reviewId,base64Decode(id));
        // const fetchReview = async () => {
        //     try {
        //         const response = await API.get(`/review_form/${id}`);
        //         if (response.success) {
        //             setReview(response.review);
        //         } else {
        //             setError("Review not found.");
        //         }
        //     } catch (error) {
        //         setError("Failed to load review.");
        //         console.error("Error fetching review:", error);
        //     } finally {
        //         setLoading(false);
        //     }
        // };

        // fetchReview();
    }, [id]);
    
    const [activeTab, setActiveTab] = useState("professional");

    // ✅ Validation Schema using Yup
    const validationSchema = Yup.object().shape({
        reviewText: Yup.string()
            .min(100, "Review must be at least 100 characters")
            .max(500, "Review must not exceed 500 characters")
            .required("Review is required"),
        reviewTitle: Yup.string()
            .min(10, "Title must be at least 10 characters")
            .max(120, "Title must not exceed 120 characters")
            .required("Review title is required"),
        languageProficiency: Yup.string().required("Please select an option"),
        agreeTerms: Yup.boolean()
            .oneOf([true], "You must agree to the terms before submitting"),
    });
    const setRatingData = (data) => {
        //console.log("Rating Data Val:", data);
        setRatingDataVal(data);
    };
    const submitReview = async (values) => {   
        console.log("Review Data:", values);
        console.log("Rating Data:", ratingDataVal);
        const payload = {
            review_id: reviewId,
            review_title: values.reviewTitle,
            review_text: values.reviewText,
            review_type_show: values.languageProficiency === "yes" ? 1 : 0,
            ratings: ratingDataVal,
            review_type: review.type,
            recipient_id:review.user_id
        };
        const response = await API.post(`/reviews/addreview`,payload);
        console.log("Response:", response);
        console.log("Payload:", payload);
        window.location.href = '/write_review';
     };
    return (
        <div>
            <MainHeader />
            <section className="pb-12 px-4 pt-[120px] custm-bg">
                <Container>
                    <Row className=" p-6 pt-0 relative  ">
                        {/* LEFT FIXED PROFILE BOX */}
                        <Col lg={4} md={12} sm={12} className="custm-bg">
                            <div className="sticky top-[70px]">
                                <div className="">

                                    <h3 className="text-[32px] font-bold text-black mb-2 leading-[1.5]">
                                        Share Your Review!
                                    </h3>
                                    <div >
                                        {review && review.profile_picture!=null?(
                                            <img
                                            src={DOMAIN_URL+review.profile_picture}
                                            className="w-[80px] h-[80px] rounded-full object-cover border border-gray-300"
                                        />
                                        ):(<img
                                            src="https://lithoreact.themezaa.com/assets/img/webp/avtar27.webp"
                                            className="w-[80px] h-[80px] rounded-full object-cover border border-gray-300"
                                        />)}
                                        {review && review.name!=null?(   <h4 className="text-lg font-bold text-gray-900 mb-0">
                                            {review.name}
                                        </h4> 
                                            ):(  <></> )}
                                            {
                                                review && review.type==1?(<><p className="text-gray-700 leading-[1.6]">
                                                    {review.company_name}
                                                </p>
                                                <p className="text-gray-700 leading-[1.2]">{review.c_start_year}</p>
                                                <p className="text-gray-700 ">Chandigard</p>
                                                <span className="text-xs bg-black text-white px-2 py-1 font-medium">
                                                    Worked Together
                                                </span></>):(<>
                                                {review && review.school_name!=null?(
                                                    <p className="text-gray-700 leading-[1.6]">
                                                    {review.school_name}
                                                    </p>
                                                ):(<></>)}
                                               
                                                {review && review.start_year>0?(<p className="text-gray-700 leading-[1.2]">{review.start_year}</p>):(<></>)}
                                                {review && review.city!=null?(<p className="text-gray-700 ">{review.city}</p>):(<></>)}
                                                {review && (<span className="text-xs bg-black text-white px-2 py-1 font-medium">
                                                    Study Together
                                                </span>)}
                                                </>)
                                            }
                                        
                                        
                                    </div>
                                </div>
                            </div>
                        </Col>

                        {/* RIGHT SCROLLABLE FORM */}
                        <Col lg={8} md={12} sm={12} className="bg-white p-3 border border-gray-300 rounded-lg shadow-md ">
                            <section className="switch-tabs py-6 px-6 pt-0">

                                <h3 className="text-[20px] font-bold text-black mb-0  bor-q-h leading-[1.5 ] py-6 px-6 pl-0 pt-0">
                                    Rate the skills
                                </h3>

                                <RatingSubmit
                                    theme="accordion-style-05"
                                    className=""
                                    themeColor="light"
                                    data={categorySkills}
                                    setRationData={setRatingData}
                                />


                                {/* <RatingSubmit
                                                theme="accordion-style-05"
                                                className=""
                                                themeColor="light"
                                                data={[
                                                    {
                                                        title: "Core Academic Skills",
                                                        skills: [
                                                            { name: "Punctuality", description: "Consistently on time for classes, assignments, and exams." },
                                                            { name: "Discipline", description: "Maintains a structured approach to studies and responsibilities." },
                                                            { name: "Hard Work", description: "Puts in consistent effort to achieve academic goals." },
                                                            { name: "Time Management", description: "Balances academic tasks and deadlines effectively." },
                                                            { name: "Self-Motivation", description: "Takes initiative and stays driven without constant supervision." },
                                                            { name: "Attention to Detail", description: "Ensures accuracy and thoroughness in academic work." },
                                                            { name: "Critical Thinking", description: "Analyzes information and evaluates arguments logically." },
                                                            { name: "Problem-Solving", description: "Identifies issues and develops effective solutions." },
                                                            { name: "Research Skills", description: "Gathers, evaluates, and synthesizes information from credible sources." },
                                                            { name: "Academic Writing", description: "Communicates ideas clearly and effectively in written form." }
                                                        ]
                                                    }, {
                                                        title: "Interpersonal & Collaborative Skills",
                                                        skills: [
                                                            { name: "Teamwork", description: "Consistently on time for classes, assignments, and exams." },
                                                            { name: "Communication Skills", description: "Expresses ideas clearly and listens actively." },
                                                            { name: "Leadership", description: "Guides and motivates peers in group settings." },
                                                            { name: "Conflict Resolution", description: "Resolves disagreements and maintains positive relationships." },
                                                            { name: "Networking", description: "Builds and maintains connections with peers and mentors." },
                                                            { name: "Presentation Skills", description: "Delivers engaging and informative presentations." },
                                                            { name: "Peer Support", description: "Helps and encourages classmates in their academic journey." },
                                                            { name: "Cultural Awareness", description: "Works effectively in diverse and multicultural academic environments." },
                                                            { name: "Feedback Reception", description: "Accepts and implements constructive feedback." },
                                                            { name: "Collaborative Learning", description: "Actively participates in group discussions and knowledge-sharing." }
                                                        ]
                                                    }
                                                    , {
                                                        title: "Creativity & Innovation",
                                                        skills: [
                                                            { name: "Creative Thinking", description: "Generates new ideas and approaches to academic challenges." },
                                                            { name: "Innovation", description: "Implements creative solutions to improve academic outcomes." },
                                                            { name: "Design Thinking", description: "Uses a human-centered approach to solve academic problems." },
                                                            { name: "Curiosity", description: "Asks questions and seeks to understand concepts deeply." },
                                                            { name: "Adaptability", description: "Adjusts to new academic challenges and changes." },
                                                            { name: "Experimentation", description: "Tests new ideas and learns from failures." },
                                                            { name: "Visual Thinking", description: "Uses diagrams, charts, and visuals to explain concepts." },
                                                            { name: "Entrepreneurial Mindset", description: "Takes ownership of academic projects and drives initiatives." },
                                                            { name: "Interdisciplinary Thinking", description: "Connects ideas across different subjects and fields." },
                                                            { name: "Problem Framing", description: "Identifies and defines academic problems effectively." }
                                                        ]
                                                    }
                                                ]}
                                            /> */}
                            </section>

                            <div className="py-6 px-6">
                                <Formik
                                    initialValues={{
                                        reviewText: "",
                                        reviewTitle: "",
                                        languageProficiency: "",
                                        agreeTerms: false,
                                    }}
                                    validationSchema={validationSchema}
                                    onSubmit={submitReview}
                                >
                                    {({ values, isSubmitting }) => (
                                        <FormikForm>
                                            {/* Write your review */}
                                            <div className="mb-6 leading-[1]">
                                                <label className="text-[20px] font-bold text-black mb-2">
                                                    Write your review
                                                </label>
                                                <Field
                                                    as="textarea"
                                                    name="reviewText"
                                                    className="w-full border border-gray-300 rounded-lg p-3 text-gray-900 focus:ring-2 focus:ring-black focus:outline-none"
                                                    placeholder="The pillows are the fluffiest I've ever felt..."
                                                    rows={4}
                                                    maxLength={500}
                                                />
                                                <div className="relative">
                                                    <div className="flex flex-col">
                                                        <ErrorMessage
                                                            name="reviewText"
                                                            component="div"
                                                            className="text-red-600 text-sm mt-0"
                                                        />
                                                        <div className="absolute right-0  text-sm text-gray-500">
                                                            {values.reviewText.length}/100 min characters
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Title your review */}
                                            <div className="mb-6">
                                                <label className="text-[20px] font-bold text-black mb-2">
                                                    Title your review
                                                </label>
                                                <Field
                                                    type="text"
                                                    name="reviewTitle"
                                                    className="w-full border border-gray-300 rounded-lg p-3 text-gray-900 focus:ring-2 focus:ring-black focus:outline-none"
                                                    placeholder="Give us the gist of your experience"
                                                    maxLength={120}
                                                />
                                                <div className="relative">
                                                    <div className="flex flex-col">
                                                        <ErrorMessage
                                                            name="reviewTitle"
                                                            component="div"
                                                            className="text-red-600 text-sm mt-1"
                                                        />
                                                        <div className="absolute right-0  text-sm text-gray-500">
                                                            {values.reviewTitle.length}/120 max characters
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Radio Buttons */}
                                            <div className="mb-10">
                                                <label className="text-[20px] font-bold text-black mb-2">
                                                    Choose how to submit your review
                                                </label>
                                                <div className="flex space-x-6">
                                                    <label className="flex items-center space-x-2">
                                                        <Field
                                                            type="radio"
                                                            name="languageProficiency"
                                                            value="yes"
                                                            className="w-5 h-5"
                                                        />
                                                        <span className="black-class"><span className="font-bold">With Your Name </span>(Your identity will be visible)</span>
                                                    </label>
                                                </div>
                                                <div className="flex space-x-6">
                                                    <label className="flex items-center space-x-2">
                                                        <Field
                                                            type="radio"
                                                            name="languageProficiency"
                                                            value="no"
                                                            className="w-5 h-5"
                                                        />
                                                        <span className="black-class"><span className="font-bold">Anonymously</span> (Your identity will remain hidden)</span>
                                                    </label>
                                                </div>
                                                <ErrorMessage
                                                    name="languageProficiency"
                                                    component="div"
                                                    className="text-red-600 text-sm mt-0"
                                                />

                                            </div>

                                            {/* Checkbox Agreement */}
                                            <div className="mb-10">
                                                <div className="flex items-start">
                                                    <Field
                                                        type="checkbox"
                                                        name="agreeTerms"
                                                        className="w-5 h-5 mt-1 border-gray-400 focus:ring-2 focus:ring-black check-l"
                                                    />
                                                    <label className="ml-3 text-gray-700 leading-[1.7]">
                                                        I certify that this review is based on my own experience.
                                                        I understand that fake reviews are not tolerated.{" "}
                                                        <a href="#" className="text-black font-bold underline">
                                                            Learn more about review fraud.
                                                        </a>
                                                    </label>
                                                </div>
                                                <ErrorMessage
                                                    name="agreeTerms"
                                                    component="div"
                                                    className="text-red-600 text-sm mt-1"
                                                />
                                            </div>


                                            {/* Submit Button */}
                                            <button className="w-[40%] btn-explore-yellow py-3 font-bold !text-[15px] !rounded-[27px]">
                                            Submit review
                                            </button>

                                        </FormikForm>
                                    )}
                                </Formik>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <FooterStyle theme="light" className="bg-black text-[#828282]" />
        </div>
    );
};

export default ReviewForm;
