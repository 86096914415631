import React, { useEffect , useState} from "react";
import { usePhotoEditor } from 'react-photo-editor';
import {  Modal  } from "react-bootstrap";
const ProfilePhotoPopup = ({ imageSrcVal, onClose }) => {
    const [file, setFile] = useState(null);
    const [show, setShow] = useState(false);

    const urlToFile = async (url, filename, mimeType) => {
        const response = await fetch(url,{ mode: "no-cors" });
        const blob = await response.blob();
        return new File([blob], filename, { type: mimeType });
    };
    const getProfileImage  = async (imgurl)=>{
        const fileData = await urlToFile(imgurl, "sample.jpg", "image/jpeg");
        console.log(fileData);
        setFile(fileData);
    }
// useEffect(()=>{
//     //console.log(imageSrcVal);
//     getProfileImage(imageSrcVal);
// },[imageSrcVal]);

  useEffect(() => {
    // Disable background scrolling when popup opens
    document.body.style.overflow = "hidden";
    
    return () => {
      // Re-enable scrolling when popup closes
      document.body.style.overflow = "auto";
    };
  }, []);

  const setFileData = (e) => {
    if (e?.target?.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
    }
  };

  const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

  return (
    <>
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-start z-50 pt-10">
      {/* Modal Container */}
      <div className="bg-[#1E1E1E] text-white rounded-lg pt-6 relative max-w-[720px] w-full mx-4 shadow-lg text-[18px]">
        {/* Close Button */}
        <button
          className="absolute top-4 right-4 text-white transition profile_pop_close_btn w-[29px] h-[29px]"
          onClick={onClose}
        >
          <i className="fas fa-times text-2xl"></i>
        </button>

        {/* Profile Photo Section */}
        <h2 className="text-lg font-semibold mb-4 pl-6">Profile photo</h2>
        <div className="flex justify-center">
          <div className="w-[280px] h-[280px] rounded-full border-4 border-gray-600 overflow-hidden">
            <img
              src={imageSrcVal}
              alt="Profile"
              className="w-full h-full object-cover"
            />
          </div>
        </div>

        {/* Action Buttons */}
        <div className="mt-6 flex justify-between border-t border-gray-600 px-6 text-[20px]">
          
          <div className="flex justify-between w-[140px]">
            <button onClick={handleShow} className="flex flex-col items-center text-white transition profile_pic_btn_cls">
                <i className="fas fa-pencil-alt text-xl"></i>
                <span className="text-sm font-semibold mt-1">Edit</span>
            </button>
            <button className="flex flex-col items-center text-white transition profile_pic_btn_cls">
                <i className="fas fa-camera text-xl"></i>
                <span className="text-sm font-semibold mt-1">Add photo</span>
            </button>
          </div>
          {/* <button className="flex flex-col items-center text-gray-300 hover:text-white transition">
            <i className="fas fa-images text-xl"></i>
            <span className="text-sm mt-1">Frames</span>
          </button> */}
          <button className="flex flex-col items-center text-white transition profile_pic_btn_cls">
            <i className="fas fa-trash-alt text-xl"></i>
            <span className="text-sm font-semibold mt-1">Delete</span>
          </button>
        </div>
      </div>
    </div>
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton className="p-4">
            <Modal.Title className="text-lg font-bold text-gray-900">Photo</Modal.Title>
        </Modal.Header>
        
    </Modal>
    </>
  );
};

export default ProfilePhotoPopup;
