// src/utils/ratingUtils.js
export const getStarColorClass = (rating) => {
    if (rating >= 5) return "yellow-i"; // Excellent
    if (rating >= 4) return "green-i "; // Very Good
    if (rating >= 3) return "greenlite-i"; // Average
    if (rating >= 2) return "redlite-i"; // Poor
    return "red-i"; // Terrible
};

export const getRatingLabel = (rating) => {
    if (rating >= 5) return "Excellent";
    if (rating >= 4) return "Very Good";
    if (rating >=3) return "Average";
    if (rating >= 2) return "Poor";
    return "Terrible";
};
