import React, { useState, useRef, useEffect } from "react";
import { Col, Container, Row, InputGroup, FormControl, ListGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import MainHeader from "../Pages/Header/MainHeader";
import FooterStyle from '../Pages/Footer/Footer';
import { IMAGE_PATHS } from '../Functions/Constant';
import { m } from 'framer-motion';
import ReviewSearch from './ComponentPage/ReviewSearch';
import Chatbot from './ComponentPage/Chatbot';

const ReviewPage = () => {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");
    const [showSuggestions, setShowSuggestions] = useState(false);
    const searchRef = useRef(null);
    const [showModal, setShowModal] = useState(false);




    // ** Detect Click Outside Search Box to Hide Suggestions **
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setShowSuggestions(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div>
            <MainHeader />
            <m.section className="py-[130px] pb-[77px] overflow-hidden custm-bg  md:py-[75px] sm:py-[50px]">
                <Container>
                    <Row className="justify-center">
                        <Col md={12} className="text-center">
                            <h4 className="text-darkgray font-bold">Write a Review – Help Build Professional Reputation</h4>
                        </Col>
                        {/* <Col lg={9} md={10} xs={12} className="text-center flex-col">
                            <h6 className="sm:mb-[30px] text-[18px] text-gray-900">
                                Your feedback helps professionals grow and organizations find the right talent.
                            </h6>
                        </Col> */}
                    </Row>

                    {/* Search Bar with Dropdown */}
                    <Row className="justify-center">
                        <Chatbot />
                    </Row>
                </Container>
            </m.section>

            <ReviewSearch bg="bg-white" />
            <FooterStyle theme="light" className="bg-black text-[#828282] seo-agency-footer_06" logo={`${IMAGE_PATHS.LOGO}`} />
        </div>
    );
};

export default ReviewPage;
