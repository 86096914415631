import axios from 'axios';

const BASE_URL = 'https://api.jobcritics.com/api';
const AxiosApi = axios.create();

AxiosApi.interceptors.request.use(
  config => {
    const token = localStorage.getItem("jwt_token_jobcritic");
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

const API = {
  get: (url, params = {}) => {
   

    const queryString = new URLSearchParams(params).toString();
    const fullUrl = url.includes('?')
      ? `${BASE_URL}${url}&${queryString}`
      : `${BASE_URL}${url}?${queryString}`;

    return AxiosApi.get(fullUrl)
      .then(response => response.data)
      .catch(error => {
        console.log('Error:', error);
        throw error;
      });
  },

  post: (url, data,) => {

    return AxiosApi.post(BASE_URL + url, data)
      .then(response => response.data)
      .catch(error => {
        throw error;
      });

  },

  put: (url, data) => {
    return AxiosApi.put(BASE_URL + url, data)
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  },

  delete: (url) => {
    return AxiosApi.delete(BASE_URL + url)
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  }

};


export default API;

