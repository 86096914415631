import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import MainHeader from "../Pages/Header/MainHeader";
import FooterStyle from "../Pages/Footer/Footer";

const Privacy = () => {
    return (
        <div>
            {/* Header */}
            <MainHeader />

            {/* Privacy Policy Section */}
            <section className="pt-[120px] pb-[50px] bg-gray-100">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <h1 className="text-[28px] font-bold text-black mb-4 black-class">Privacy Policy</h1>
                            <p className="black-class">Effective Date:  March 18, 2025</p>

                            <h2 className="text-[22px] font-bold mt-4 black-class">1. Introduction</h2>
                            <p className="black-class">
                                Personal Information” means any information that may be used to identify an individual, including but not limited to a first and last name, a home or other physical address, an email address, or other contact information, whether at work or at home. In general, you can visit the Website without telling us who you are or revealing any Personal Information about yourself.
                            </p>
                            <p className="black-class">
                                We believe that protecting the confidentiality and privacy of our registered users is very important. For this reason, our basic features are designed to protect your privacy. Additionally, we provide settings that allow you to customize your privacy and confidentiality levels to your comfort.
                            </p>

                            <h2 className="text-[22px] font-bold mt-4 black-class">2. Information We Collect</h2>
                            <p className="black-class">When you set up an individual user account or an employer account on JobCritics, request information by signing up for email alerts, complete a form on JobCritics, upload a resume (in whole or in part), contact us for service or technical support (via a third-party website or directly), we create a "Profile" for you. This Profile includes information you provide directly or indirectly.</p>
                            <p className="black-class">The information we collect may include, but is not limited to:</p>
                            <ul className="list-disc pl-5 black-class">
                                <li>First and last name.</li>
                                <li>Email address.</li>
                                <li>Educational details.</li>
                                <li>Professional career information (e.g., job title, work experience).</li>
                                <li>Telephone number (including mobile phone number).</li>
                                <li>Location, address, and gender.</li>
                                <li>Occupation, interests, and messages you send to users.</li>
                                <li>Company information, company ratings, company reviews, interview questions and answers, interview advice, interview tips, FAQs, and other details submitted or extracted from your resume or third-party websites.</li>
                                <li>Content uploaded or actively sent to our Site</li>
                            </ul>
                            <p className="black-class">
                                For employer accounts, we also store passwords and other relevant information.
                            </p>
                            <p className="black-class">
                                We may update your Profile with information you permit us to extract from time to time. This data is collected to improve user experience on JobCritics, provide relevant search results, and help users find the exact information they need about companies.
                            </p>
                            <p className="black-class">
                                You may permit us to share information in your Profile with other website visitors, prospective employers, recruiters, and others. When you connect to JobCritics through a third-party or Social Networking Site, we may share your information as we deem fit. We may also share certain non-personally identifiable information, such as job titles, with advertisers, recruiters, or employers.
                            </p>

                            <p className="black-class">
                                Subject to visibility settings you control, subscribers to our services may view information in your Profile. To protect your anonymity, your Profile may not include or link to company reviews, interview questions, salary information, or similar content, depending on your chosen visibility settings. For more details, please refer to our <b>Terms of Use</b> and <b>Community Guidelines</b>.
                            </p>

                            <h2 className="text-[22px] font-bold mt-4 black-class">3. Types of Information Collected</h2>
                            <p className="black-class">We collect information from users with prior consent to provide services through the Website. The specific types of information we collect are outlined in our Terms of Use. While some information is mandatory for service provision, much of it is optional, and users may provide it at their discretion.</p>


                            <h2 className="text-[22px] font-bold mt-4 black-class">4. Purpose of Collection and Usage</h2>
                            <p className="black-class">We collect information to help users discover companies, prepare for interviews, and learn about workplace experiences through JobCritics. The information we collect may be used for the following purposes:</p>
                            <ul className="list-disc pl-5 black-class">
                                <li>To provide personalized content, services, and promotional materials for a better user experience.</li>
                                <li>To moderate and display employer or company reviews, interview reviews, gender-specific ratings, gender ratios, interview questions, interview tips, salary reviews, and other content submitted by users.</li>
                                <li>To customize and improve the features, performance, and support of the Website.</li>
                                <li>To show relevant information when you connect to JobCritics through a third-party or Social Networking Site and allow you to share job and profile information with your connections.</li>
                                <li>To provide relevant advertising, including interest-based advertising from us and third parties.</li>
                                <li>To offer recruiting services to employers.</li>
                                <li>For internal operations, including troubleshooting, data analysis, testing, research, and service improvement.</li>
                                <li>To communicate with you about our services.</li>
                                <li>To create aggregate and statistical data that does not identify you individually and can be commercialized.</li>
                                <li>For other purposes you authorize as you interact with JobCritics.</li>
                            </ul>
                            <p className="black-class">We do not collect financial information such as credit card/debit card numbers or bank account details.</p>

                            <h2 className="text-[22px] font-bold mt-4 black-class">5. Information Sharing and Disclosure</h2>
                            <p className="black-class">
                                JobCritics does not rent, sell, or share personal information about you with other people or non-affiliated companies, except in the following circumstances:
                            </p>
                            <ul className="list-disc pl-5 black-class">
                                <li>To provide products or services you’ve requested.</li>
                                <li>With trusted partners who work on behalf of or with JobCritics under confidentiality agreements. These partners may use your personal information to help JobCritics communicate with you about offers and promotions.</li>
                                <li>In response to subpoenas, court orders, or legal processes, or to establish or exercise our legal rights or defend against legal claims.</li>
                                <li>To investigate, prevent, or take action regarding illegal activities, suspected fraud, potential threats to physical safety, violations of our Terms of Use, or as otherwise required by law.</li>
                                <li>If JobCritics is acquired by or merged with another company. In such cases, we will notify you before your information is transferred and becomes subject to a different privacy policy.</li>

                            </ul>
                            <p className="black-class">
                                JobCritics may display targeted advertisements based on personal information. Advertisers may assume that users who interact with or click on targeted ads meet the targeting criteria (e.g., women ages 18-24 from a specific geographic area). However, JobCritics does not provide personal information to advertisers when you interact with or view a targeted ad. By interacting with or viewing an ad, you consent to the possibility that the advertiser will assume you meet the targeting criteria.
                            </p>

                            <h2 className="text-[22px] font-bold mt-4 black-class">6. User Data Deletion</h2>
                            <p className="black-class">
                                If you wish to delete the information linked to your JobCritics account (including information acquired from third-party login providers such as Gmail or LinkedIn), please email us at support@jobcritics.comwith the subject line "Delete User Account." The sender email must match the account you wish to delete. Your data will be completely destroyed within 72 hours or 3 business days, and you will be notified once the process is complete.
                            </p>

                            <h2 className="text-[22px] font-bold mt-4 black-class">7. Cookies and Tracking Technologies</h2>
                            <p className="black-class">
                                Some of our web pages use “cookies” and other tracking technologies. A “cookie” is a small text file that may be used to collect information about website activity. Some cookies and technologies may recall Personal Information previously indicated by you.
                            </p>
                            <p className="black-class">
                                Most browsers allow you to control cookies, including whether to accept them and how to remove them. You may set your browser to notify you when you receive a cookie or block cookies altogether. However, if you choose to block cookies, you may need to re-enter your user ID and password to access certain parts of the Website.
                            </p>
                            <p className="black-class">
                                Tracking technologies may record information such as:
                            </p>
                            <ul className="list-disc pl-5 black-class">
                                <li>Internet domain and host names.</li>
                                <li>IP addresses.</li>
                                <li>Browser software and operating system types.</li>
                                <li>Clickstream patterns.</li>
                                <li>Dates and times of Website access</li>
                            </ul>
                            <p className="black-class">
                            We use cookies and tracking technologies to improve the Website and your web experience. We may also analyze non-personal information for trends and statistics.
                            </p>

                            <h2 className="text-[22px] font-bold mt-4 black-class">8. Security Practices and Procedures</h2>
                            <p className="black-class">
                            We fully comply with all applicable Indian laws and cooperate with law enforcement inquiries. We may disclose your personal details in response to lawful requests from authorities or to prevent imminent breaches of the law.
                            </p>
                            <h2 className="text-[22px] font-bold mt-4 black-class">9. Your Consent</h2>
                            <p className="black-class">
                            By using this Website, you consent to the terms of our Privacy Policy and to [JobCritics]’s processing of Personal Information for the purposes outlined above and as explained where we collect Personal Information on the Website.
                            </p>
                            <h2 className="text-[22px] font-bold mt-4 black-class">10. Contact Us</h2>
                            <p className="black-class">
                            If you have any questions about this Privacy Policy, please contact us at:
                            </p>
                            <p className="font-medium black-class">
                                Email: <a href="mailto:support@jobcritics.com" className="text-blue-500 font-medium"> support@jobcritics.com</a>
                            </p>
                            <p className="font-medium black-class">Address: Sector 34 A, Chandigarh, India</p>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Footer */}
            <FooterStyle theme="light" className="bg-black text-[#828282]" />
        </div>
    );
};

export default Privacy;