import React, { useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import { InfoCircle } from "react-bootstrap-icons";

export default function InfoTooltip({ text }) {
//   useEffect(() => {
//     // Ensure tooltips work properly
//     const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
//     tooltipTriggerList.forEach(tooltip => new window.bootstrap.Tooltip(tooltip));
//   }, []);

  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id="tooltip-top"><div style={{ textTransform: "none", textAlign: "left" }}>
      {text}
    </div></Tooltip>}
    >
      <span className="d-inline-block" data-bs-toggle="tooltip">
        <i className=" fas fa-info-circle black-class font-bold text-[20px] cursor-pointer" ></i>
      </span>
    </OverlayTrigger>
  );
}
