import React, { useEffect, useState } from "react";
import { IMAGE_PATHS } from "../Functions/Constant";

const ConfirmationPopup = ({ isOpen, title, message, onClose, onConfirm, loading, showCancelButton = true }) => {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    if (isOpen) {
      document.documentElement.style.overflow = "hidden";
      document.body.style.overflow = "hidden";

      // Loader ko 3 sec tak dikhane ke liye timeout lagaya
      setShowLoader(true);
      const timer = setTimeout(() => {
        setShowLoader(false);
      }, 1000);

      return () => {
        clearTimeout(timer);
        document.documentElement.style.overflow = "auto";
        document.body.style.overflow = "auto";
      };
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      {showLoader ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <img src={IMAGE_PATHS.LOADER} alt="Loading..." className="w-12 h-12" />
        </div>
      ) : (
        <div className="bg-white p-6 rounded-2xl shadow-xl w-[420px] relative flex flex-col items-center justify-center">
          {/* Close Button */}
          <button
            className="absolute top-3 right-3 text-gray-600 hover:text-black"
            onClick={onClose}
            disabled={loading}
          >
            <i className="fas fa-times text-lg"></i>
          </button>

          {/* Title */}
          <h4 className="text-lg font-semibold text-black text-left w-full">{title}</h4>
          <p className="text-gray-600 mt-2">{message}</p>

          {/* Buttons */}
          <div className="flex mt-6 w-full">
            {showCancelButton ? (
              <>
                <button className="btn-explore-white mr-[12px]" onClick={onClose} disabled={loading}>
                  Cancel
                </button>
                <button className="btn-explore-yellow" onClick={onConfirm} disabled={loading}>
                  Confirm
                </button>
              </>
            ) : (
              <button className="btn-explore-yellow" onClick={onConfirm} disabled={loading}>
                OK
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfirmationPopup;
