const HeaderData = [
    {
        title: 'Home',
        link: '/'
    },
    {
        title: 'For Employer',
        link: '/'
        
    },
    {
        title: 'For Employee',
        link: '/',
    },
    {
        title: 'Features',
         link: '/'
    }
]

export default HeaderData