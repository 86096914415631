import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { IMAGE_PATHS } from "../Functions/Constant";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

const CustomModal = ({ show, handleClose }) => {
  const navigate = useNavigate();

  const handleModalClose = () => {
    handleClose();
    navigate("/");
  };

  const handleGoogleLogin = () => {
    window.location.href = "https://api.jobcritics.com/api/auth/google";
  };
  return (
    <Modal show={show} onHide={handleModalClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="text-center w-100">
          <img src={IMAGE_PATHS.LOGO} alt="Logo" className="logo-cls" width="50" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center p-[50px]">
        <h4 className="font-bold text-gray-900">Sign in to unlock the best of JobCritic.</h4>

        <Row className="flex justify-center">
                        <Col lg={6} md={12} className="flex justify-center">
                          {/* Google Sign-in Button */}
                          <Button className="btn-explore-white flr-d flex items-center j-sa" onClick={handleGoogleLogin}>
                            <img src={IMAGE_PATHS.GOOGLE} alt="Google" className="icon-social w-[8%] " />
                            Continue with Google
                          </Button>
                        </Col>
                      </Row>
        
                      {/* <Row className="flex justify-center">
                        <Col lg={6} md={12} className="flex justify-center">

                          <Button className="btn-explore-white my-3 flr-d flex items-center j-sa">
                            <img src={IMAGE_PATHS.IN} alt="LinkedIn" className="icon-social w-[8%] " />
                            Continue with LinkedIn
                          </Button>
                        </Col>
                      </Row> */}
                       <p className="text-sm text-gray-600 mt-3">
          By proceeding, you agree to our <a href="/term_policy" className=" yellow-class">Terms of Use </a>
          and confirm you have read our <a href="/privacy" className=" yellow-class">Privacy and Cookie Statement</a>.
        </p>

        <p className="text-xs text-gray-500">
          This site is protected by reCAPTCHA and the Google
          <a href="https://policies.google.com/privacy" className=" yellow-class"> Privacy Policy</a> and
          <a href="https://policies.google.com/terms" className="yellow-class"> Terms of Service</a> apply.
        </p>
      </Modal.Body>
    </Modal>
  );
};

const LoginForm = () => {
  const [showModal, setShowModal] = useState(true);

  return (
    <div>
      <CustomModal show={showModal} handleClose={() => setShowModal(false)} />
    </div>
  );
};

export default LoginForm;
