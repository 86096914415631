import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./Chatbot.css";
import { Col, Container, Row, InputGroup, FormControl, ListGroup } from "react-bootstrap";
import API from '../../Api/Api';
import { IMAGE_PATHS, base64Encode, base64Decode, DOMAIN_URL } from "../../Functions/Constant";
import Buttons from '../../Components/Button/Buttons'
const Chatbot = () => {
    const [chatOpen, setChatOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    const [currentStep, setCurrentStep] = useState(0);
    const [userInput, setUserInput] = useState("");
    const [userResponses, setUserResponses] = useState({});
    const [isLastQuestion, setIsLastQuestion] = useState(false);
    const [userName, setUserName] = useState("");
    const chatRef = useRef(null);
    const [questions, setQuestions] = useState([
        { text: "Hello! Please tell me whom you want to search.", type: "input", key: "city" },
        { text: "Okay, I will help you find sdas. Can you tell me the city?", type: "input", key: "city" },
        { text: "Great! Did you study together or work together?", type: "options", options: ["Studied together", "Worked together"], key: "relation" }
    ]);
    const chatBoxRef = useRef(null);

    useEffect(() => {
        if (chatBoxRef.current) {
            chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }
    }, [messages]);

    const handleSearch = async () => {

        setChatOpen(true);
        const firstQuestion = 'Hello! Please tell me whom you want to search.';
        setTimeout(() => {
            setMessages(prevMessages => [
                ...prevMessages,
                { text: firstQuestion, sender: "bot", type: "input", showInput: true, time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) }
            ]);
            setCurrentStep(1);
        }, 1000);
    };
    const handleBack = () => {
        console.log("Current Step", currentStep);

        if (currentStep >= 1) {
            const previousQuestion = questions[currentStep - 2]; // Get previous question
            if (!previousQuestion) {
                console.error("Previous question is undefined!");
                return; // Stop execution if no previous question
            }

            console.log("Previous Question", previousQuestion);

            setCurrentStep(currentStep - 1);

            setMessages(prevMessages => [
                ...prevMessages.map((msg, index) =>
                    index === prevMessages.length - 1 ? { ...msg, showInput: false } : msg
                ),
                { text: 'Back', sender: "user", time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), isUserResponse: true, animate: true }
            ]);

            setMessages(prevMessages => [
                ...prevMessages,
                {
                    text: previousQuestion.text || "No question text",
                    sender: "bot",
                    type: previousQuestion.type || "text",
                    showInput: true,
                    options: previousQuestion.options || null,
                    time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                    animate: true
                }
            ]);
        }
    };

    useEffect(() => {
        console.log("Updated Questions:", questions);
    }, [questions]);
    const updateSearch = async (response) => {
        const firstQuestionText = `Okay, I will help you find ${response}. Can you tell me the city?`;
        const updatedQuestions = [
            { text: "Hello! Please tell me whom you want to search.", type: "input", key: "city" },
            { text: firstQuestionText, type: "input", key: "city" },
            { text: "Great! Did you study together or work together?", type: "options", options: ["Studied together", "Worked together"], key: "relation" }
        ];
        setUserName(response);
        setQuestions(updatedQuestions);
        setTimeout(() => {
            let userResponse = response;
            const currentQuestion = questions[currentStep - 1];
            setUserResponses(prevResponses => ({
                ...prevResponses, // Keep previous responses
                [currentQuestion.key]: userResponse  // Add/update current response
            }));
            console.log("User Responses:", userResponses);
            setMessages(prevMessages => [
                ...prevMessages.map((msg, index) =>
                    index === prevMessages.length - 1 ? { ...msg, showInput: false } : msg
                ),
                { text: userResponse, sender: "user", time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), isUserResponse: true, animate: true }
            ]);
            setUserInput("");
            setMessages(prevMessages => [
                ...prevMessages,
                {
                    text: updatedQuestions[currentStep].text,
                    sender: "bot",
                    type: updatedQuestions[currentStep].type,
                    showInput: true,
                    options: updatedQuestions[currentStep].options || null,
                    time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                    animate: true
                }
            ]);
            setCurrentStep(prevStep => prevStep + 1);
        }, 1000);
    };
    const handleResponse = async (response) => {
        if (!response.trim())
            return;

        let userResponse = response;
        const currentQuestion = questions[currentStep - 1];
        if (currentStep == 1) {
            updateSearch(response);

        } else {
            setUserResponses(prevResponses => ({
                ...prevResponses, // Keep previous responses
                [currentQuestion.key]: userResponse  // Add/update current response
            }));
            console.log("User Responses:", userResponses);
            setMessages(prevMessages => [
                ...prevMessages.map((msg, index) =>
                    index === prevMessages.length - 1 ? { ...msg, showInput: false } : msg
                ),
                { text: userResponse, sender: "user", time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), isUserResponse: true, animate: true }
            ]);
            setUserInput("");

            let updatedQuestions = [...questions];

            if (userResponse === "Studied together") {
                updatedQuestions.push({ text: "Can you tell me the school, college, or university name?", type: "input", key: "institution" });
                updatedQuestions.push({ text: "In which year were you together?", type: "year", key: "year" });
            } else if (userResponse === "Worked together") {
                updatedQuestions.push({ text: "Can you tell me your same company name?", type: "input", key: "company" });
                updatedQuestions.push({ text: "In which year were you together?", type: "year", key: "year" });
            }

            setQuestions(updatedQuestions); // Update state
            console.log("Ques", updatedQuestions);
            if (currentStep < updatedQuestions.length) {
                setTimeout(() => {
                    setMessages(prevMessages => [
                        ...prevMessages,
                        {
                            text: updatedQuestions[currentStep].text,
                            sender: "bot",
                            type: updatedQuestions[currentStep].type,
                            showInput: true,
                            options: updatedQuestions[currentStep].options || null,
                            time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                            animate: true
                        }
                    ]);
                    setCurrentStep(prevStep => prevStep + 1);
                }, 1000);
            } else {
                setIsLastQuestion(true); // Mark that the last question is answered
            }
        }

    };

    useEffect(() => {
        if (isLastQuestion) {
            console.log("Final Payload before API:", userResponses);
            sendFinalData(userResponses, userName);  // Send all responses now
        }
    }, [userResponses, isLastQuestion]); // Runs when userResponses updates

    // **Function to Send API Request**
    const sendFinalData = async (data, userName) => {
        console.log("Sending data:", data, userName);

        try {
            const payload = {
                name: userName,
                city: data.city,
                company: data.company,
                search_type: data.relation == 'Worked together' ? 1 : 2,
                company_year: data.year,
                school: data.institution,
                school_year: data.year
            };

            const response = await API.post('/user/searchuser', payload);
            console.log(response.users);
            //    const response = await fetch("https://api.jobcritics.com/", {
            //        method: "POST",
            //        headers: {
            //            "Content-Type": "application/json"
            //        },
            //        body: JSON.stringify(data)
            //    });

            // const responsedata = await response.json();
            const responsedata = response.users.length;
            let userDataArr = [];

            if (responsedata > 0) {
                response.users.forEach(user => {
                    const userData = {
                        user_id: user.user_id,
                        type: payload.search_type, tag_data_id: payload.search_type == 1 ? user.company_id : user.school_id
                    }
                    userDataArr.push({ text: "Continue", user: userData });
                });
                // Scenario 1: Matches found
                setMessages(prevMessages => [
                    ...prevMessages,
                    {
                        text: `${responsedata} matches found. Click continue to rate them.`,
                        sender: "bot",
                        showInput: true,
                        animate: true,
                        type: 'staticOptions',
                        options: [{ text: "Continue", user: { user_id: 1, payload: payload } }]
                    }
                ]);
            } else {

                // Scenario 2: No matches found
                setMessages(prevMessages => [
                    ...prevMessages,
                    {
                        text: `Sorry, I couldn’t find das. Would you like to rate them now?`,
                        sender: "bot",
                        showInput: true,
                        animate: true,
                        type: 'staticOptions',
                        options: [
                            { text: "Yes", user: { user_id: 0, payload: payload } },
                            { text: "Search Again", user: { user_id: -1, payload: payload } }
                        ]
                    }
                ]);
            }
        } catch (error) {
            console.error("Error submitting data:", error);
        }
    };
    const handleContinue = async (users) => {
        console.log("Proceeding to rate users:", users);
        if (users.user_id == 1) {
            const queryString = encodeURIComponent(JSON.stringify(users.payload));
            // const payload = {
            //     user_id:users.user_id, type:users.type, tag_data_id:users.tag_data_id
            // };
            // const response = await API.post('/user/initiate-rating', payload);
            // console.log(response);
            window.location.href = '/search_result/?query=' + queryString;
        } else if (users.user_id == 0) {


            const payload = {
                name: users.payload.name, city: users.payload.city, company: users.payload.company, search_type: users.payload.search_type,
                company_year: users.payload.company_year, school: users.payload.school, school_year: users.payload.school_year
            };
            const response = await API.post('/user/usercreate-for-rating', payload);
            console.log(response);
            window.location.href = '/review_form/' + base64Encode(response.review_id);
        } else if (users.user_id == -1) {
            handleSearchAgain();
        }
    };


    const handleSearchAgain = () => {
        console.log("User chose to search again.");
    };
    const resetChat = () => {
        setMessages([]); // Reset messages to an empty array
        setUserInput(""); // Reset input field
        setChatOpen(false); // Close modal
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (chatRef.current && !chatRef.current.contains(event.target)) {
                resetChat(); // Call resetChat when clicking outside
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (chatOpen) {
            document.body.style.overflow = "hidden"; // Disable scrolling
        } else {
            document.body.style.overflow = "auto"; // Enable scrolling when closed
        }

        return () => {
            document.body.style.overflow = "auto"; // Cleanup on unmount
        };
    }, [chatOpen]);

    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (chatBoxRef.current) {
                setIsScrolled(chatBoxRef.current.scrollTop > 0);
            }
        };

        if (chatBoxRef.current) {
            chatBoxRef.current.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (chatBoxRef.current) {
                chatBoxRef.current.removeEventListener("scroll", handleScroll);
            }
        };
    }, [chatBoxRef.current]);



    return (
        <><Col lg={7} md={12} xs={12} className="flex justify-center ">
            {!chatOpen && (
                // <InputGroup className="custom-focus-shadow rounded-[20px] hov-c">
                //     <InputGroup.Text className="bg-white border rounded-full px-3">
                //         <i className="fas fa-search text-gray-500 cursor-pointer" onClick={handleSearch} ></i>
                //     </InputGroup.Text>
                //     <FormControl
                //         type="text"
                //         placeholder="Search..."
                //         className="border rounded-full py-2 text-sm focus:ring-0 focus:border-gray-300 cursor-pointer sr-cl"
                //         // value={searchTerm}
                //         readOnly // Prevent direct typing
                //         onClick={handleSearch} />
                //     {/* GIF Inside InputGroup */}
                //     <div className="absolute right-5 top-1/2 transform -translate-y-1/2">
                //         <img src={IMAGE_PATHS.Ai} alt="Loading animation" className="w-6 h-6" />
                //     </div>
                // </InputGroup>
                <Buttons
                    ariaLabel="button"
                    href="#"
                    className="!rounded-[21px] w-[80%] mx-[10px] font-medium font-serif uppercase  lg:mb-[15px] landscape:lg:mb-[15px] !border-none !hover:border-none flex justify-center transition-all duration-700"
                    themeColor={["#556fff", "#ff798e"]}
                    size="lg"
                    color="#fff"
                    iconPosition="before"
                    title="Search for review..."
                    onClick={handleSearch}
                    icon={
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className="w-6 h-6">
                            <path d="M10,21.236,6.755,14.745.264,11.5,6.755,8.255,10,1.764l3.245,6.491L19.736,11.5l-6.491,3.245ZM18,21l1.5,3L21,21l3-1.5L21,18l-1.5-3L18,18l-3,1.5ZM19.333,4.667,20.5,7l1.167-2.333L24,3.5,21.667,2.333,20.5,0,19.333,2.333,17,3.5Z" />
                        </svg>
                    }
                />




            )}
        </Col>
            {chatOpen && <div className="chat-overlay"></div>}
            <div ref={chatRef} className={`chat-container relative shadow-lg bg-white !p-0  ${chatOpen ? "b-d" : ""}`}>
                {chatOpen && (
                    <div>
                        <div className={`chat-header !m-0 !p-[13px] ${isScrolled ? "sc-s" : ""}`}>
                            <div>Start your search</div>
                            <button className="close-button" onClick={resetChat} >×</button>
                        </div>
                        <div className="chat-popup">

                            <div className="chat-box overflow-auto" ref={chatBoxRef}>
                                {messages && messages.map((msg, index) => (
                                    <div key={index} className="message-wrapper">
                                        <div className="message-container">
                                            <article className={` ${msg.sender === "bot" ? "article-div" : "article-div isLeft"}`}>
                                                {msg.sender === "bot" ? (
                                                    <div className="msg-grid-left">
                                                        <figure className="image">
                                                            <img alt="" className="Media is-original is-unselectable is-contain" src="https://storage.googleapis.com/media.landbot.io/678226/channels/0ALU81JK7HUHVBYZFINKA0FOZXZNXF4E.png" />
                                                        </figure>
                                                        <span class="MessageDate"><b class="MessageDate__text">{msg.time}</b></span>
                                                    </div>
                                                ) : null}
                                                <div className={` ${msg.sender === "bot" ? "msg-grid-center" : "msg-grid-center user-message fade-in left-in"}`}>
                                                    <div className={msg.sender === "bot" ? "MessageBubble bot-bubble" : "user-bubble"}>
                                                        <div className="MessageBubble__content">
                                                            <div className="MessageBubbleText">
                                                                <p>{msg.text}</p>
                                                            </div>
                                                            {msg.showInput && msg.sender === "bot" && msg.type === 'input' && (
                                                                <div className="InputText is-animate-messages-on">
                                                                    <div className="field has-addons is-marginless">
                                                                        <div className="has-icons-right">
                                                                            <input className="input" placeholder="Type here..." type="text" value={userInput} onChange={(e) => setUserInput(e.target.value)} onKeyDown={(e) => e.key === "Enter" && handleResponse(userInput)} />
                                                                            <button type="button" className="input-icon-send-button" onClick={() => handleResponse(userInput)}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" x="3650" y="3688"><path fill="#ffc000" d="M1.1 21.757l22.7-9.73L1.1 2.3l.012 7.912 13.623 1.816-13.623 1.817-.01 7.912z"></path></svg>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <p className="enterText">Press enter to send</p>
                                                                </div>
                                                            )}
                                                            {msg.type === "year" && msg.showInput && msg.sender === "bot" && (
                                                                <div className="InputText is-animate-messages-on">
                                                                    <div className="field has-addons is-marginless">
                                                                        <div className="has-icons-right">
                                                                            <input
                                                                                className="input"
                                                                                placeholder="Enter year (YYYY)"
                                                                                type="text"
                                                                                value={userInput}
                                                                                maxLength="4"
                                                                                onChange={(e) => {
                                                                                    const value = e.target.value.replace(/\D/g, ""); // Allow only numbers
                                                                                    if (value.length <= 4)
                                                                                        setUserInput(value); // Restrict to exactly 4 digits
                                                                                }}
                                                                                onKeyDown={(e) => {
                                                                                    if (e.key === "Enter" && userInput.length === 4) {
                                                                                        handleResponse(userInput);
                                                                                    }
                                                                                }} />
                                                                            <button
                                                                                type="button"
                                                                                className="input-icon-send-button"
                                                                                onClick={() => userInput.length === 4 && handleResponse(userInput)}
                                                                                disabled={userInput.length !== 4} // Disable if not exactly 4 digits
                                                                            >
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                                                                                    <path fill="#ffc000" d="M1.1 21.757l22.7-9.73L1.1 2.3l.012 7.912 13.623 1.816-13.623 1.817-.01 7.912z"></path>
                                                                                </svg>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    {userInput.length !== 4 && (
                                                                        <p className="enterText" style={{ color: "red" }}>
                                                                            Please enter exactly 4 digits.
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            )}

                                                        </div>
                                                    </div>
                                                    {msg.type === "options" && msg.options && msg.showInput && (
                                                        <div className="options-wrapper">
                                                            <p className="choose-text">CHOOSE AN OPTION</p>
                                                            <div className="options-container">
                                                                {msg.options.map((option, i) => (
                                                                    <button key={i} className="btn-explore-yellow" onClick={() => handleResponse(option)}>
                                                                        {option}
                                                                    </button>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )}
                                                    {msg.type === "staticOptions" && msg.options && msg.showInput && (
                                                        <div className="options-wrapper">
                                                            <p className="choose-text">CHOOSE AN OPTION</p>
                                                            <div className="options-container">
                                                                {msg.options.map((option, i) => (
                                                                    <button
                                                                        key={i}
                                                                        className="btn-explore-yellow"
                                                                        onClick={() => handleContinue(option.user)}
                                                                    >
                                                                        {option.text}
                                                                    </button>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )}

                                                </div>
                                                {msg.sender === "user" ? (
                                                    <div class="msg-grid-right"><span class="MessageDate userMessagedate"><b class="sc-fufdwm dqzGHg MessageDate__text">{msg.time}</b></span></div>
                                                ) : null}
                                            </article>
                                            {msg.showInput && msg.sender === "bot" ? (
                                                <div class="back-button-container" onClick={() => handleBack()}><article class="back-grid-row"><div class="back-grid-left"></div><div class="sc-lnsxGb kQehpR msg-grid-center" style={{ paddingLeft: "9px" }}><div class="sc-hGNhLO iuYYuj BackButton"><button type="button" class="sc-blHHSb eLwlKU BackButton__Button custom-button button" aria-label="Back"><span class="BackButton__Icon"><span class="icon"><svg height="20" viewBox="0 0 24 24" class="sc-gQaihK iuxxgz"><path fill="var(--form_buttons_color)" d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"></path></svg></span></span><span class="BackButton__Label is-unselectable">Back</span></button></div></div></article></div>
                                            ) : null}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default Chatbot;
