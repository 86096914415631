import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

// Libraries
import { BrowserRouter } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import { LazyMotion, domMax } from "framer-motion";
import { GoogleOAuthProvider } from "@react-oauth/google";

// css
import "./Assets/css/icons.css";
import "./Assets/css/global.css";
import "./Assets/css/pages.css";
import "./index.scss";
import "./Assets/css/custom.css";

// ✅ Add Your Google Client ID Here
const GOOGLE_CLIENT_ID = "647703613465-vtc4nl8fuvib4ivs5fvig1oek9m2hb6b.apps.googleusercontent.com"; // Replace with your actual Google Client ID

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
    <LazyMotion features={domMax}>
      <ParallaxProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ParallaxProvider>
    </LazyMotion>
  </GoogleOAuthProvider>
);
